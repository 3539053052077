import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { Link } from 'react-router-dom';

import { sessionActive, generateAlert, paginationProps } from '../../utils/utils';

const Centers = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const removeCenter = (id) => {
        let uri = `${process.env.REACT_APP_API_URI}center/${id}`;
        
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.delete(uri, config)
        .then(response => {
            loadData();

            let result = {
                type: 'success',
                message: response.data.message
            };
            generateAlert(result);
        })
        .catch(err => {
            console.log(err);
        })
    };

    const deleteCenter = (e) => {
        let uuid = e.target.dataset.uuid;
        // console.log(uuid);
        Swal.fire({
            title: 'Borrando institución...',
            text: "¿Está seguro de querer borrar esta institución?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {
            if (result.isConfirmed) {
                removeCenter(uuid);
            }
        });
    };

    const columns = [
        {
            name: '#',
            width: "65px",
            cell: (row) => <b>{ row.id }</b>
        },
        {
            name: 'Institución',
            width: (row) => {
                if (Cookies.get('Manager') !== undefined && Cookies.get('Manager') === '1') {
                    return "250px";
                }

                return "450px";
            },
            cell: (row) => <div className="group">
                { (Cookies.get('Manager') !== undefined && Cookies.get('Manager') === '1') && (
                    <span>{ `${(row.name).slice(0, 20)}` }{ row.name.length > 20 ? "..." : "" }</span>
                ) }
                { (Cookies.get('Manager') === undefined || Cookies.get('Manager') !== '1') && (
                    <span>{ `${(row.name).slice(0, 35)}` }{ row.name.length > 35 ? "..." : "" }</span>
                ) }
                {/* { row.name.length > 20 && (
                    <div class="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                        <span class="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.name }</span>
                    </div>
                ) } */}
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.name }</span>
                </div>
            </div>
        },
        {
            name: 'Departamento',
            width: "200px",
            cell: (row) => <span>{ row.state }</span>
        },
        
        {
            name: 'Municipio',
            width: "200px",
            cell: (row) => <span>{ row.city }</span>
        },
        {
            name: 'Activo',
            width: "90px",
            cell: (row) => <div>{ row.status === 1 ?  <FontAwesomeIcon icon="fa-solid fa-circle-check" className="text-green-600" /> : <FontAwesomeIcon icon="fa-solid fa-circle-xmark" className="text-error" /> }</div>
        },
        {
            name: 'Ver / Editar',
            cell: (row) => <div>
                <Link to={ `/centers/${row.id}` } className="edit-button bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-theme" >
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </Link>
            </div>
        },
    ];

    if (Cookies.get('Manager') !== undefined && Cookies.get('Manager') === '1') {
        columns.push({
            name: 'Borrar',
            cell: (row) => <div>
                <button data-uuid={ row.id } className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700" 
                    onClick={ deleteCenter } 
                >
                    <FontAwesomeIcon icon="fa-solid fa-user-xmark" />
                </button>
            </div>
        });
    }

    const loadData = useCallback(() => {
        // setBtnEdit(null);
        let uri = `${process.env.REACT_APP_API_URI}center`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            setData(response.data);
        })
        .catch(err => {
            console.log(err);
        });
        
        // getEditButtons();
        setIsLoading(true);
    }, []);

    useEffect(() => {
        sessionActive(location, navigate);
        if (isLoading === false) {
            loadData();
        }

    }, [location, navigate, loadData, isLoading]);

    return (
        <div id="Centers">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold">
                    Instituciones que brindan servicios
                </h1>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6">
                <Link to="/centers/new" className="bg-theme px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-md text-base text-white transition-colors hover:bg-dark-theme disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" disabled={ data.length > 0 ? false : true }>
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-house-medical" />Nueva institución
                </Link>
                <Link to="/attention-schedules" className="bg-white px-4 py-3 rounded-md text-base text-theme border-2 border-theme transition-colors hover:bg-theme disabled:bg-opacity-80 hover:text-white disabled:cursor-not-allowed md:w-auto w-full" disabled={ data.length > 0 ? false : true }>
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-calendar-days" />Ver horarios
                </Link>
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <DataTableExtensions
                        columns={ columns }
                        data={ data }
                        filterPlaceholder="Buscar..."
                        print={ false }
                        export={ false }
                    >
                        <DataTable
                            pagination
                            columns={ columns }
                            data={ data }
                            dense
                            striped
                            responsive
                            paginationComponentOptions={ paginationProps }
                        />
                    </DataTableExtensions>
                </div>
            </section>

            {/* <SingleModal
                open={ modalButton }
                width="md:max-w-screen-xl"
                content={ <NewCenter 
                    title="Ingresando un nuevo centro de atención"
                    icon="fa-solid fa-pen"
                    data={ loadData }
                    button="Ingresar"
                /> }  
            /> */}
        </div>
    );
};

export default Centers;
