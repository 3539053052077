import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Reports = () => {
    return (
        <div id="Reports">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold">
                    Reportes
                </h1>
            </div>
            <div className="flex justify-center">
                <p className="text-lg font-normal my-5">
                    Seleccione una opción para tener una vista previa del reporte y poder generarlo en un archivo PDF o Excel. 
                </p>
            </div>
            <div className="mt-6">
                <div className="mx-auto flex flex-wrap mb-10">
                    <div className="lg:w-1/2 w-full p-2">
                        <Link to="/reports/categories-report" className="btn-report">
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" size='3x' />
                            Categorías más buscadas
                        </Link>
                    </div>
                    <div className="lg:w-1/2 w-full p-2">
                        <Link to="/reports/states-report" className="btn-report">
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" size='3x' />
                            Departamentos con más busquedas
                        </Link>
                    </div>
                    {/* <div className="lg:w-1/2 w-full p-2">
                        <Link to="/reports/cities-report" className="btn-report">
                            Municipios con más busquedas
                        </Link>
                    </div> */}
                    <div className="lg:w-1/2 w-full p-2">
                        <Link to="/reports/clients-report" className="btn-report">
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" size='3x' />
                            Tipos de usuarios con más busquedas
                        </Link>
                    </div>
                    
                    <div className="lg:w-1/2 w-full p-2">
                        <Link to="/reports/centers-report" className="btn-report">
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" size='3x' />
                            <div className="text-center">Listado de instituciones</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reports;
