import { Fragment } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
	faPowerOff, 
	faChartColumn,
	faBriefcaseMedical,
	faHouseMedicalFlag,
	faList,
	faUsers,
	faFileExport,
	faIdCard,
	faClipboardUser,
	faUserPlus,
	faCircleCheck,
	faCircleXmark,
	faUserPen,
	faUserXmark,
	faLock,
	faHouseMedical,
	faPen,
	faCalendarDays,
	faMagnifyingGlass,
	faArrowLeft,
	faHandHoldingHand,
	faHouseFlag,
	faFilePdf,
	faFileExcel
} from '@fortawesome/free-solid-svg-icons';

// Theme components
import Sidebar from './components/layout/Sidebar';
import Navbar from './components/layout/Navbar';

/* Views */

// Auth Views
import Login from './components/views/Auth/Login';
import Profile from './components/views/Auth/Profile';

// Home Views
import Dashboard from './components/views/Home/Dashboard';

// Users Views
import Users from './components/views/Users/Users';
import NewUser from './components/views/Users/NewUser';
import UpdateUser from './components/views/Users/UpdateUser';

// Categories Views
import Category from './components/views/Categories/Category';
import NewCategory from './components/views/Categories/NewCategory';
import UpdateCategory from './components/views/Categories/UpdateCategory';

// Services Views
import Services from './components/views/Services/Services';
import NewService from './components/views/Services/NewService';
import UpdateService from './components/views/Services/UpdateService';

// Center views
import Centers from './components/views/Centers/Centers';
import NewCenter from './components/views/Centers/NewCenter';
import UpdateCenter from './components/views/Centers/UpdateCenter';

// Attention Schedules Views
import Schedules from './components/views/Schedules/Schedules';
import NewSchedule from './components/views/Schedules/NewSchedule';
import UpdateSchedule from './components/views/Schedules/UpdateSchedule';

// Report views
import Reports from './components/views/Reports/Reports';
import CategoriesReport from './components/views/Reports/CategoriesReport';
import StatesReport from './components/views/Reports/StatesReport';
import CitiesReport from './components/views/Reports/CitiesReport';
import UsersReport from './components/views/Reports/UsersReport';
import CentersReport from './components/views/Reports/CentersReport';

// Error pages
import NotFound from './components/errors/NotFound';

import PrivateRoutes from './components/content/Auth/PrivateRoutes';

import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/css/App.css';

library.add(faPowerOff, faChartColumn, faBriefcaseMedical, faHouseMedicalFlag, faList, faFileExport, faUsers, faIdCard, faClipboardUser, faUserPlus, faCircleCheck, faCircleXmark, faUserPen, faUserXmark, faLock, faHouseMedical, faPen, faCalendarDays, faMagnifyingGlass, faArrowLeft, faHouseFlag, faHandHoldingHand, faFilePdf, faFileExcel);

const App = () => {	
	const location = useLocation();

	return (
		<div className="App">
			<div className="flex">
				{ (location.pathname !== "/" && location.pathname !== '/not-found') && (
					<Sidebar />
				) }
				{/* container sm:p-6 px-0 py-6 2xl:ml-72 2xl:mr-64 md:ml-40 md:mr-20 sm:ml-28 sm:mr-14 ml-9 mr-9 */}
				<div className={ `h-screen ${ location.pathname === "/" ? "flex-1" : "w-full min-w-full max-w-full" }` } >
					{ (location.pathname !== "/" && location.pathname !== '/not-found') && (
						<Navbar />
					)}
					<div className={ location.pathname !== "/" ? "sm:px-6 px-0 pb-6 pt-20 2xl:ml-72 2xl:mr-64 md:ml-30 md:mr-15 sm:ml-28 sm:mr-14 ml-9 mr-9" : "" } >
						<Fragment>
							<Routes>
								<Route path="/" element={ <Login /> } />
								<Route path="/dashboard" element={ <Dashboard /> } />
								
								<Route path="/categories" element={ <Category /> } />
								<Route path="/categories/new" element={ <NewCategory /> } />
								<Route path="/categories/:id" element={ <UpdateCategory /> } />

								<Route path="/services" element={ <Services /> } />
								<Route path="/services/new" element={ <NewService /> } />
								<Route path="/services/:id" element={ <UpdateService /> } />

								<Route path="/centers" element={ <Centers /> } />
								<Route path="/centers/new" element={ <NewCenter /> } />
								<Route path="/centers/:id" element={ <UpdateCenter /> } />
								
								<Route path="/attention-schedules" element={ <Schedules /> } />
								<Route path="/attention-schedules/new" element={ <NewSchedule /> } />
								<Route path="/attention-schedules/:id" element={ <UpdateSchedule /> } />
								
								<Route path="/reports" element={ <Reports /> } />
								<Route path="/reports/categories-report" element={ <CategoriesReport /> } />
								<Route path="/reports/states-report" element={ <StatesReport /> } />
								<Route path="/reports/cities-report" element={ <CitiesReport /> } />
								<Route path="/reports/clients-report" element={ <UsersReport /> } />
								<Route path="/reports/centers-report" element={ <CentersReport /> } />

								<Route element={ <PrivateRoutes /> }>
									<Route path="/users" element={ <Users /> } />
									<Route path="/users/new" element={ <NewUser /> } />
									<Route path="/users/:id" element={ <UpdateUser /> } />
								</Route>
								
								<Route path="/profile" element={ <Profile /> } />
								
								<Route path="/not-found" element={ <NotFound /> } />
								<Route path="*" element={ <Navigate to='/not-found' replace /> } />
							</Routes>
						</Fragment>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
