import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState, createRef, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactToPrint from 'react-to-print';
import exportFromJSON from 'export-from-json';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { paginationProps } from "../../utils/utils";

const reportRef = createRef();
const date = new Date();

const CentersReport = () => {
    
    const onBeforeGetContentResolve = useRef(null);

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState([]);
    const [preLoader, setPreloader] = useState(null);

    const columns = [
        {
            name: '#',
            width: "65px",
            cell: (row) => <b>{ row.id }</b>
        },
        {
            name: 'Institución',
            width: "250px",
            cell: (row) => <div className="group">
                <span>{ `${(row.name).slice(0, 20)}` }{ row.name.length > 20 ? "..." : "" }</span>
                {/* { row.name.length > 20 && (
                    <div class="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                        <span class="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.name }</span>
                    </div>
                ) } */}
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.name }</span>
                </div>
            </div>
        },
        {
            name: 'Tipo',
            width: "200px",
            cell: (row) => <span>{ row.type }</span>
        },
        {
            name: 'Departamento',
            width: "200px",
            cell: (row) => <span>{ row.state }</span>
        },
        
        {
            name: 'Municipio',
            width: "200px",
            cell: (row) => <span>{ row.city }</span>
        },
        {
            name: 'Estado',
            width: "90px",
            cell: (row) => <div>{ row.status === 1 ?  "Activa": "Inactiva" }</div>
        },
        {
            name: 'Registro',
            width: "300px",
            cell: (row) => <div>{ row.created }</div>
        },
        {
            name: 'Actualización',
            width: "300px",
            cell: (row) => <div>{ row.updated }</div>
        }
    ];

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}record/centers`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {            
            
            setData(response.data);
            setPreloader(true);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    }, []);

    const generatePdfFile = useCallback(() => {
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                resolve();
            }, 2000);
        });
    }, []);

    const generateFileExcel = () => {  
        if (Array.isArray(data)) {
            let info = [];

            for (let i = 0; i < data.length; i++) {
                info.push({
                    "N°": (i + 1),
                    "Identificador": data[i].id,
                    "Institución": data[i].name,
                    "Tipo": data[i].type,
                    "Dirección": data[i].address,
                    "Encargado": data[i].manager,
                    "Correo electrónico": data[i].email === null ? "No registrado": data[i].email,
                    "Teléfono": data[i].phone,
                    "Departamento": data[i].state,
                    "Municipio": data[i].city,
                    "Estado": data[i].status === 1 ?  "Activa": "Inactiva",
                    "Registro": data[i].created,
                    "Actualización": data[i].updated
                });
            }
            
            const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-instituciones`;
            const exportType =  exportFromJSON.types.xls;
            
            exportFromJSON({ data: info, fileName: fileName, exportType: exportType });
        }
    };

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, [isLoading, loadData]);

    return (
        <div id="CentersReport">
            <div className="flex mx-auto flex-wrap justify-between mb-10 mt-6 w-full">
                <div className="flex flex-wrap justify-start">
                    <Link to="/reports" className="bg-white px-4 py-3 rounded-lg text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" >
                        <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Regresar
                    </Link>
                </div>
                <div className="flex flex-wrap justify-start">
                    <ReactToPrint
                        content={ () => reportRef.current }
                        onBeforeGetContent={ generatePdfFile }
                        trigger={ () => <button className="bg-pdf px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-md text-base text-white transition-colors hover:bg-dark-pdf disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" disabled={ data.length > 0 ? false : true }>
                            <FontAwesomeIcon className="mr-3" icon="fa-solid fa-file-pdf" />Generar archivo PDF
                        </button> }
                    />
                    {/* <Pdf targetRef={ reportRef } filename={ `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-categorias.pdf` }>
                        {({ toPdf }) => <button className="bg-pdf px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-md text-base text-white transition-colors hover:bg-dark-pdf disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" disabled={ data.length > 0 ? false : true } onClick={ toPdf } >
                            <FontAwesomeIcon className="mr-3" icon="fa-solid fa-file-pdf" />Generar archivo PDF
                        </button>}
                    </Pdf> */}
                    <button className="bg-excel px-4 py-3 md:mb-0 mb-3 rounded-md text-base text-white transition-colors hover:bg-dark-excel disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" onClick={ generateFileExcel } disabled={ data.length > 0 ? false : true }>
                        <FontAwesomeIcon className="mr-3" icon="fa-solid fa-file-excel" />Generar archivo Excel
                    </button>
                </div>
            </div>
            <section className="w-full flex justify-center">
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg w-full">
                    <div className="m-5 bg-white" >
                        { preLoader !== null && (
                            <div className="my-8 bg-white">
                                <div className="text-center">
                                    <h2 className="text-2xl font-bold">Tipos de usuarios con más busquedas</h2>
                                </div>
                                <br />
                                <div className="mt-5">
                                    <div className="hidden">
                                        <div className="m-8 bg-white" ref={ reportRef } >
                                            <div className="text-center">
                                                <h2 className="text-2xl font-bold">Tipos de usuarios con más busquedas</h2>
                                            </div>
                                            <div className="my-5 flex justify-center table-auto">
                                                <table className="w-full text-center border-collapse border border-slate-600 text-xs">
                                                    <thead className="bg-slate-600 text-white">
                                                        <tr>
                                                            <th className="px-4 py-2 border border-slate-600">#</th>
                                                            <th className="px-4 py-2 border border-slate-600">Institución</th>
                                                            <th className="px-4 py-2 border border-slate-600">Tipo</th>
                                                            <th className="px-4 py-2 border border-slate-600">Departamento</th>
                                                            <th className="px-4 py-2 border border-slate-600">Municipio</th>
                                                            <th className="px-4 py-2 border border-slate-600">Estado</th>
                                                            <th className="px-4 py-2 border border-slate-600">Registro</th>
                                                            <th className="px-4 py-2 border border-slate-600">Actualización</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-dark">
                                                        { data.map((item, index) => {
                                                            return (
                                                                <tr key={ index }>
                                                                    <td className="px-4 py-2 border border-slate-600">
                                                                        <strong>{ index + 1 }</strong>
                                                                    </td>
                                                                    <td className="px-4 py-2 border border-slate-600">{ item.name }</td>
                                                                    <td className="px-4 py-2 border border-slate-600">{ item.type }</td>
                                                                    <td className="px-4 py-2 border border-slate-600">{ item.state }</td>
                                                                    <td className="px-4 py-2 border border-slate-600">{ item.city }</td>
                                                                    <td className="px-4 py-2 border border-slate-600">{ item.status === 1 ? "Activo" : "Inactivo" }</td>
                                                                    <td className="px-4 py-2 border border-slate-600">{ item.created }</td>
                                                                    <td className="px-4 py-2 border border-slate-600">{ item.updated }</td>
                                                                </tr>
                                                            );                                                    
                                                        }) }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="text-center text-base font-normal mt-5">
                                                <span>Informe generado él <i>{ date.getDay() }-{ date.getMonth() }-{ date.getFullYear() }</i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-results report table-auto">
                                        <DataTableExtensions
                                            columns={ columns }
                                            data={ data }
                                            filterPlaceholder="Buscar..."
                                            print={ false }
                                            export={ false }
                                        >
                                            <DataTable
                                                pagination
                                                columns={ columns }
                                                data={ data }
                                                customStyles={ { 
                                                    rows: {
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }
                                                } }
                                                dense
                                                striped
                                                responsive
                                                paginationComponentOptions={ paginationProps }
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                                <div className="text-center font-normal">
                                    <span>Informe generado él <i>{ date.getDay() }-{ date.getMonth() }-{ date.getFullYear() }</i></span>
                                </div>
                            </div>
                        ) }
                        { preLoader === null && (
                            <div className="w-full flex justify-center">
                                <svg className="animate-spin md:w-1/6 ms:w-2/6 w-3/6 p-6 text-secondary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        ) }
                    </div>  
                </div>
            </section>
        </div>
    );
};

export default CentersReport;
