import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { generateAlert, validateIcon } from '../../utils/utils';

const UpdateCategory = () => {
    const { id } = useParams();
    // const location = useLocation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();

    // const [isLoading, setIsLoading] = useState(null);
    const [preLoader, setPreloader] = useState(null);
    const [data, setData] = useState([]);
    const [newIcon, setNewIcon] = useState(false);

    const loadData = useCallback(() => {
        // setIsLoading(null);
        if (id !== null || id !== undefined) {
            let uri = `${process.env.REACT_APP_API_URI}category/${id}`;
    
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('Bearer')}`,
                }
            }
            
            axios.get(uri, config)
            .then(response => {
                // console.log(response.data);
                let defaultValues = {
                    name: response.data.name,
                    description: response.data.description,
                    icon: response.data.icon,
                    status: response.data.status
                }

                setPreloader([]);
                setData(defaultValues);
                reset(defaultValues);
            })
            .catch(err => {
                console.log(err);
            });
        }
    }, [id, reset]);

    const changeIcon = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setNewIcon(true);
        }

        if (!is_checked) {
            setNewIcon(false);
        }
    }

    const sendData = (data) => {
        // let verifyIcon = null;

        if (data.change_icon) {
            validateIcon(data.file);
            // verifyIcon = validateIcon(data.file);

            data.icon = data.file[0];
            delete data.file;
        } else {
            delete data.file;
        }

        let uri = `${process.env.REACT_APP_API_URI}category/${id}?_method=PUT`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }
        // console.log(data);
        axios.post(uri, data, config)
        .then(response => {
            let result = {
                type: 'success',
                message: response.data.message
            };

            generateAlert(result);
            
            reset();
            clearErrors();
            setNewIcon(false);
            navigate('/categories');
        })
        .catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <div id="UpdateCategory">
            <div className="pt-5 flex items-center justify-center">
                <div className=" mx-auto text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-dark-theme sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </div>
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Actualizando categoría</h3>
                </div>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6">
                <Link to="/categories" className="bg-white px-4 py-3 rounded-lg text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" >
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Regresar
                </Link>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <form id="update-category" onSubmit={ handleSubmit(sendData) } >
                        <div className={ `flex ${ preLoader !== null ? "flex-wrap -m-2" : "justify-center" }` }>
                            { preLoader !== null && (
                                <>
                                    <div className="w-full mb-4">
                                        <h2 className="text-base font-normal">Los campos con&nbsp;<span className="text-error">*</span>&nbsp;son requeridos.</h2>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="names" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nombre de la categoría&nbsp;<span className="text-error">*</span></label>
                                            <input type="text" id="new-name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                {...register("name", {
                                                    required: "El nombre de la categoría es requerido"
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="name"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                            <div className="flex justify-center">
                                                <label htmlFor="change_icon" className="flex items-center cursor-pointer relative mb-4">
                                                    <input type="checkbox" id="change_icon" 
                                                        className="sr-only"
                                                        {...register("change_icon")}
                                                        onChange={ changeIcon }
                                                    />
                                                    <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                                    <span className="ml-3 text-gray-600 text-base font-medium">Cambiar icono</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="relative mb-2 text-center">
                                            { newIcon === false && (
                                                <div className="text-center">
                                                    <div className="inline-block bg-theme p-4 rounded-md">
                                                        <img
                                                            src={ (process.env.REACT_APP_URI_PHOTO + data.icon) }
                                                            className="w-32"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            ) }
                                            { newIcon === true && (
                                                <>
                                                    <span className="leading-7 text-base text-gray-600 font-semibold block mb-1">Icono de la categoría&nbsp;<span className="text-error">*</span></span>
                                                    <label>
                                                        <input type="file" id="file" className="text-base transition-colors file:mr-5 file:py-2 file:px-6 file:rounded-md file:border-0 file:text-base file:font-medium file:bg-body file:text-white hover:file:cursor-pointer hover:file:bg-theme"
                                                            {...register("file", {
                                                                required: "El icono es requerido"
                                                            })}
                                                        />
                                                    </label>
                                                    <div>
                                                        <ErrorMessage
                                                            errors={ errors }
                                                            name="file"
                                                            render={({ message }) => <span className="error-message">{ message }</span>}
                                                        />
                                                    </div>
                                                </>
                                            ) }
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="description" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Descripción de la categoría&nbsp;<span className="text-error">*</span></label>
                                            <textarea id="new-description" className="w-full h-32 resize-none bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                                {...register("description", {
                                                    required: "La descripción de la categoría es requerida"
                                                })}
                                            ></textarea>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="description"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/2 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="u-status" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Estado&nbsp;<span className="text-error">*</span></label>
                                            <select id="u-status" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            {...register("status", {
                                                required: "Seleccione un estado"
                                            })} >
                                                <option value={ 1 }>Activo</option>
                                                <option value={ 2 }>Inactivo</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="status"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) }
                            { preLoader === null && (
                                <svg className="animate-spin md:w-1/6 ms:w-2/6 w-3/6 p-6 text-secondary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) }
                        </div>

                        <div className={ `w-full pt-8 ${ preLoader !== null ? "flex" : "hidden" } justify-end` }>
                            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-theme font-bold text-white transition-colors hover:bg-dark-theme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-0 sm:w-auto sm:text-base">Actualizar</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default UpdateCategory;
