import axios from 'axios';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';

import AvatarMale from '../../assets/images/user-male.png';

const Navbar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}auth/profile`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            setData(response.data);
        })
        .catch(err => {
            console.log(err);
        });

        setIsLoading(true);
    }, []);

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
        
    }, [isLoading, loadData]);

    return (
        <div className="w-full fixed transition-colors bg-dark-theme z-10 px-6 py-4 shadow-md">
            <div className="flex justify-end items-center space-x-4">
                { data && (
                    <span className="font-semibold text-white text-lg">{ data.username }</span>
                ) }
                { !data && (
                    <span className="font-semibold text-white text-lg animate-pulse">Cargando...</span>
                ) }
                <img
                    src={ AvatarMale }
                    className="rounded-full w-8"
                    alt=""
                />
            </div>
        </div>
    );
};

export default Navbar;
