import axios from 'axios';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { useRef, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';

import { Map, GeolocateControl, NavigationControl, FullscreenControl, Marker } from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import { generateAlert, validatePhoto, sessionActive } from '../../utils/utils';

const UpdateCenter = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const phonePattern = /(6|7)*[0-9]{3}[0-9]{5}/gi;
    const facebookPattern = /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook|fb|m.facebook).(?:com|me)\/(?:(?:w)*#!\/)?(?:pages\/)?(?:[?w-]*\/)?(?:profile.php?id=(?=d.*))?([w-]*)?/i;
    const instagramPattern = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram|instagr|m.instagram)\/?.(?:com|.am)\/?([w-]*)?/i;
    const twitterPattern = /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com|t.co)\/?([w-]*)?/i;

    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [preLoader, setPreloader] = useState(null);

    const [data, setData] = useState([]);
    const [newPhoto, setNewPhoto] = useState(false);
    const [coordinatesManual, setCoordinatesManual] = useState(false);

    const [centerTypes, setCenterType] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [clientsTypes, setClientTypes] = useState([]);
    const [services, setServices] = useState([]);
    const [moreInfo, setMoreInfo] = useState([]);

    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    // const [selectedCategories, setSelectedCategories] = useState([]);

    const mapDiv = useRef(null);

    const loadTypes = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}center/types`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            setCenterType(response.data);
        })
        .catch(err => {
            console.log(err);
        });
        
        setIsLoading(true);
    }, []);

    const loadStates = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}state`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            setStates(response.data);
        })
        .catch(err => {
            console.log(err);
        });
        
        setIsLoading(true);
    }, []);

    const loadCities = useCallback((value) => {
        let uri = `${process.env.REACT_APP_API_URI}city/${value}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            setCities(response.data);
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    const loadCategories = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}category/all/active`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            // console.log(response.data);
            let info = [];

            for (let i = 0; i < response.data.length; i++) {
                info.push({
                    value: response.data[i].id,
                    label: response.data[i].name,
                });
            }
            
            setCategories(info);
        })
        .catch(err => {
            console.log(err);
        });
        
        setIsLoading(true);
    }, []);

    const filterCity = (e) => {
        let state_id = e.target.value;

        loadCities(state_id);
    }

    const loadClients = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}client/types`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            let info = [];

            for (let i = 0; i < response.data.length; i++) {
                info.push({
                    value: response.data[i].id,
                    label: response.data[i].name,
                });
            }
            
            setClientTypes(info);
        })
        .catch(err => {
            console.log(err);
        });
        
        setIsLoading(true);
    }, []);

    const changePhoto = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setNewPhoto(true);
        }

        if (!is_checked) {
            setNewPhoto(false);
        }
    }

    const useMap = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setCoordinatesManual(true);
        }

        if (!is_checked) {
            setCoordinatesManual(false);
            
            // loadMap();
        }
    }

    const loadServices = useCallback((items) => {
        let uri = `${process.env.REACT_APP_API_URI}service/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }
        // console.log(items);
        let data = {
            items: items
        }

        axios.post(uri, JSON.stringify(data), config)
        .then(response => {
            setServices(response.data);
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    const selectTypes = useCallback((e) => {
        setSelectedTypes(e);
    }, []);

    const selectCategories = useCallback((e) => {
        // setSelectedCategories(e);
        loadServices(e);
    }, [loadServices]);

    const selectServices = useCallback((e) => {
        setSelectedServices(e);
    }, []);

    const moreInfoCenter = (e) => {
        setMoreInfo(e);
    }

    const sendData = (data) => {
        let verifyPhoto = false;

        if (data.file !== undefined && data.file.length > 0) {
            verifyPhoto = validatePhoto(data.file);
        }

        let uri = `${process.env.REACT_APP_API_URI}center/${id}?_method=PUT`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }
        
        try {
            data.more_info = (moreInfo.getData().length === 0) ? null : moreInfo.getData();

            moreInfo.setData("");
        } catch (error) {
            data.more_info = null;
        }

        if (selectedTypes.length < 1) {
            let result = {
                type: 'error',
                message: "Seleccione los tipos de personas que atenderá"
            };

            generateAlert(result);

            return;
        }

        data.clients = selectedTypes;
        data.clients_count = selectedTypes.length;

        if (selectedServices.length < 1) {
            let result = {
                type: 'error',
                message: "Seleccione los servicios"
            };

            generateAlert(result);

            return;
        }

        data.services = selectedServices;
        data.services_count = selectedServices.length;

        if (document.getElementById('new-longitude').value === "" || document.getElementById('new-latitude').value === "") { 
            let result = {
                type: 'error',
                message: "Ubique en el mapa la localización del centro de atención"
            };

            generateAlert(result);

            return;
        }

        data.longitude = document.getElementById('new-longitude').value;
        data.latitude = document.getElementById('new-latitude').value;

        if (verifyPhoto) {
            data.photo = data.file[0];
        }
        // console.log(data);
        axios.post(uri, data, config)
        .then(response => {
            // console.log(response.data);

            let result = {
                type: 'success',
                message: response.data.message
            };

            generateAlert(result);
            
            reset();
            clearErrors();
            setNewPhoto(false);

            navigate('/centers');
        })
        .catch(err => {
            console.log(err);
        });
    };

    const loadMap = (latitude, longitude) => {
        const map = new Map({
            container: mapDiv.current, // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: [longitude , latitude], // starting position [lng, lat]
            zoom: 17,
            minZoom: 2,
            maxZoom: 24          
        });
        
        const geocoder = new MapboxGeocoder({
            accessToken: process.env.REACT_APP_MAP_TOKEN,
            countries: 'sv',
            bbox: [-90.0955545723, 13.1490168319, -87.7235029772, 14.4241327987],
            filter: (item) => {
                return item.context.map((i) => {
                    return (i.id.split('.').shift() === 'country' && i.text === 'El Salvador');
                }).reduce((acc, cur) => {
                    return acc || cur;
                });
            },
            placeholder: "Buscar",
        });

        const locateU = new GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        });
        
        map.addControl(geocoder);
        map.addControl(new NavigationControl());
        map.addControl(new FullscreenControl());
        map.addControl(locateU);

        let size = 150;

        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),
            onAdd: function () {
                const canvas = document.createElement('canvas');

                canvas.width = this.width;
                canvas.height = this.height;
                
                this.context = canvas.getContext('2d');
            },
            render: function() {
                const duration = 1000;
                const t = (performance.now() % duration) / duration;
                
                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * t + radius;
                const context = this.context;
                
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = `rgba(42, 98, 157, ${1 - t})`;
                context.fill();
                
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(30, 74, 120, 1)';
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();
                
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;
                
                map.triggerRepaint();
                
                return true;
            }
        };

        map.on('load', () => {
            map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
            map.addSource('dot-point', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [longitude, latitude] // icon position [lng, lat]
                            }
                        }
                    ]
                }
            });
            map.addLayer({
                'id': 'layer-with-pulsing-dot',
                'type': 'symbol',
                'source': 'dot-point',
                 'layout': {
                    'icon-image': 'pulsing-dot'
                }
            });
        });

        let marker;

        /* map.on('load', () => {
            map.resize();
        }); */

        map.on('click', (e) => {
            document.getElementById('new-latitude').value = JSON.stringify(e.lngLat.lat);
            document.getElementById('new-longitude').value = JSON.stringify(e.lngLat.lng);

            if (typeof marker == 'object') {
                marker.remove();
            }

            marker = new Marker().setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map);
            map.flyTo({
                center: e.lngLat,
                zoom: 15
            });
        });

        geocoder.on('result', (ev) => {
            document.getElementById('new-longitude').value = ev.result.geometry.coordinates[0];
            document.getElementById('new-latitude').value = ev.result.geometry.coordinates[1];
        });

        locateU.on('geolocate', (e) => {
            let lon = e.coords.longitude;
            let lat = e.coords.latitude;
            let position = [lon, lat];

            document.getElementById('new-longitude').value = lon;
            document.getElementById('new-latitude').value = lat;
            
            map.flyTo({
                center: position,
                zoom: 9
            });
        
        });
    };

    const loadData = useCallback(() => {        
        // console.log(id);
        if (id !== null || id !== undefined) {
            let uri = `${process.env.REACT_APP_API_URI}center/details/${id}`;
    
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('Bearer')}`,
                }
            }
            
            axios.get(uri, config)
            .then(response => {               
                let defaultValues = {
                    name: response.data.name,
                    short_name: response.data.short_name,
                    type: response.data.type,
                    email: response.data.email,
                    phone: response.data.phone,
                    facebook: response.data.facebook,
                    instagram: response.data.instagram,
                    twitter: response.data.twitter,
                    photo: response.data.photo,
                    clients: response.data.clients,
                    categories: response.data.categories,
                    services: response.data.services,
                    manager: response.data.manager,
                    state: response.data.state,
                    city: response.data.city,
                    address: response.data.address,
                    more_info: response.data.more_info,
                    latitude: response.data.latitude,
                    longitude: response.data.longitude,
                };

                loadCities(response.data.state);
                selectServices(response.data.services);
                selectTypes(response.data.clients);
                selectCategories(response.data.categories);
                
                loadServices(response.data.categories);

                document.getElementById('new-latitude').value = response.data.latitude;
                document.getElementById('new-longitude').value = response.data.longitude;

                loadMap(response.data.latitude, response.data.longitude);
                // console.log(defaultValues);
                reset(defaultValues);
                setData(defaultValues);
                setPreloader([]);
            })
            .catch(err => {
                console.log(err);
            });
        }
    }, [id, loadServices, reset, selectCategories, selectServices, selectTypes, loadCities]);   

    useEffect(() => {
        sessionActive(location, navigate);

        if (isLoading === false) {
            loadTypes();
            loadClients();
            loadCategories();
            // loadServices();
            loadStates();
            loadData();
        }
    }, [isLoading, loadData, loadTypes, loadClients, loadCategories, loadServices, loadStates, navigate, location]);

    return (
        <div id="UpdateCenter">
            <div className="pt-5 flex items-center justify-center">
                <div className=" mx-auto text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-dark-theme sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </div>
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Actualizando institución</h3>
                </div>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6">
                <Link to="/centers" className="bg-white px-4 py-3 rounded-lg text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" >
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Regresar
                </Link>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <form id="new-center" onSubmit={ handleSubmit(sendData) } >
                        <div className={ `flex ${ preLoader !== null ? "flex-wrap -m-2" : "justify-center" }` }>
                            { preLoader !== null && (
                                <>
                                    <div className="w-full mb-4">
                                        <h2 className="text-base font-normal">Los campos con&nbsp;<span className="text-error">*</span>&nbsp;son requeridos.</h2>
                                    </div>
                                    <div className="w-full p-2 mb-3">
                                        <h2 className="text-center text-xl font-bold">Datos generales de la institución</h2>
                                    </div>
                                    <div className="p-2 xl:w-1/2 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="name" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nombre completo de la institución&nbsp;<span className="text-error">*</span></label>
                                            <input type="text" id="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                {...register("name", {
                                                    required: "El nombre es requerido"
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="name"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/2 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="short_name" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nombre corto de la institución&nbsp;<span className="text-error">*</span></label>
                                            <input type="text" id="short_name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                {...register("short_name", {
                                                    required: "El nombre corto es requerido"
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="short_name"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="type" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Tipo de institución&nbsp;<span className="text-error">*</span></label>
                                            <select 
                                                id="new-type" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ "null" }
                                                {...register("type", {
                                                    required: "Seleccione un tipo",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione el tipo de centro de atención";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una opción]</option>
                                                { centerTypes.map((item, index) => {
                                                    return (
                                                        <option value={ item.id } key={ index }>{ item.name }</option>
                                                    );
                                                }) }    
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="type"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="email" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Correo electrónico</label>
                                            <input type="email" id="new-email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                {...register("email", {
                                                    pattern: {
                                                        value: pattern,
                                                        message: "El correo electrónico es inválido"
                                                    }
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="email"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="phone" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Teléfono</label>
                                            <input type="text" id="new-phone" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                {...register("phone", {
                                                    required: "El número de teléfono es requerido",
                                                    pattern: {
                                                        value: phonePattern,
                                                        message: "El número de teléfono es inválido"
                                                    }
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="phone"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                            <div className="flex justify-center">
                                                <label htmlFor="change_photo" className="flex items-center cursor-pointer relative mb-4">
                                                    <input type="checkbox" id="change_photo" 
                                                        className="sr-only"
                                                        {...register("change_photo")}
                                                        onChange={ changePhoto }
                                                    />
                                                    <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                                    <span className="ml-3 text-gray-600 text-base font-medium">Cambiar icono</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="relative mb-2 text-center">
                                            { newPhoto === false && (
                                                <div className="text-center">
                                                    { (data.photo !== null) && (
                                                        <div className="inline-block bg-theme p-4 rounded-md">
                                                            <img
                                                                src={ (process.env.REACT_APP_URI_PHOTO + data.photo) }
                                                                className="w-60"
                                                                alt=""
                                                            />
                                                        </div>
                                                    )}
                                                    { (data.photo === null) && (
                                                        <div className="inline-block bg-theme p-4 rounded-md">
                                                            <h1 className="text-white font-bold">Carga de foto no realizada</h1>
                                                        </div>
                                                    ) }
                                                </div>
                                            ) }
                                            { newPhoto === true && (
                                                <>
                                                    <label>
                                                        <input type="file" id="file" className="md:w-96 text-base transition-colors file:mr-5 file:py-2 file:px-6 file:rounded-md file:border-0 file:text-base file:font-medium file:bg-body file:text-white hover:file:cursor-pointer hover:file:bg-theme"
                                                            {...register("file")}
                                                        />
                                                    </label>
                                                </>
                                            ) }
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                        <label htmlFor="client_types" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Tipo de persona que se atenderán&nbsp;<span className="text-error">*</span></label>
                                            <Select
                                                options={ clientsTypes }
                                                defaultValue={ data.clients }
                                                id="new-client_types"
                                                // ref={ ref => inputTypes = ref }
                                                isMulti={ true }
                                                name="client_types"
                                                placeholder="Seleccione..."
                                                onChange={ selectTypes }
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="categories" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Categoría de Servicios&nbsp;<span className="text-error">*</span></label>
                                            <Select
                                                defaultValue={ data.categories }
                                                options={ categories }
                                                id="categories"
                                                // ref={ ref => inputCategories = ref }
                                                isMulti={ true }
                                                name="categories"
                                                placeholder="Seleccione..."
                                                onChange={ selectCategories }
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="services" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Tipo de Servicios&nbsp;<span className="text-error">*</span></label>
                                            <Select
                                                defaultValue={ data.services }
                                                options={ services }
                                                id="new-services"
                                                // ref={ ref => inputServices = ref }
                                                isMulti={ true }
                                                name="services"
                                                placeholder="Seleccione..."
                                                onChange={ selectServices }
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full p-2 my-3">
                                        <h2 className="text-center text-xl font-bold">Datos de contacto & Redes sociales</h2>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="manager" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Contacto de la institución&nbsp;<span className="text-error">*</span></label>
                                            <input type="text" id="new-manager" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                {...register("manager", {
                                                    required: "El nombre del contacto de la institución es requerido"
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="manager"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="state" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Departamento&nbsp;<span className="text-error">*</span></label>
                                            <select 
                                                id="new-state" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ "null" }
                                                {...register("state", {
                                                    required: "Seleccione el departamento",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione el departamento";
                                                        }
                                                    },
                                                })}
                                                onChange={ filterCity }
                                            >
                                                <option hidden disabled selected value="null">[Seleccione un departamento]</option>
                                                { states.map((item, index) => {
                                                    return (
                                                        <option value={ item.id } key={ index }>{ item.name }</option>
                                                    );
                                                }) }    
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="state"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="city" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Municipio&nbsp;<span className="text-error">*</span></label>
                                            <select 
                                                id="new-city" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.city !== undefined ? data.city : "null" }
                                                {...register("city", {
                                                    required: "Seleccione el municipio",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione el municipio";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled value="null">[Seleccione un municipio]</option>
                                                { cities && (
                                                    cities.map((item, index) => {
                                                        if (data.city === item.id) {
                                                            return (
                                                                <option value={ item.id } key={ index } selected>{ item.name }</option>
                                                            );
                                                        } else {
                                                            return (
                                                                <option value={ item.id } key={ index }>{ item.name }</option>
                                                            );
                                                        }
                                                    })
                                                ) }    
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="city"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="facebook" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Enlace de Facebook</label>
                                            <input type="text" id="facebook" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                placeholder='https://www.facebook.com/page'
                                                {...register("facebook", {
                                                    pattern: {
                                                        value: facebookPattern,
                                                        message: "Enlace de Facebook inválido"
                                                    }
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="facebook"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="instagram" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Enlace de Instagram</label>
                                            <input type="text" id="instagram" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                placeholder='https://www.instagram.com/page'
                                                {...register("instagram", {
                                                    pattern: {
                                                        value: instagramPattern,
                                                        message: "Enlace de Instagram inválido"
                                                    }
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="instagram"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 xl:w-1/3 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="twitter" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Enlace de Twitter</label>
                                            <input type="text" id="twitter" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                placeholder='https://www.twitter.com/page'
                                                {...register("twitter", {
                                                    pattern: {
                                                        value: twitterPattern,
                                                        message: "Enlace de Twitter inválido"
                                                    }
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="twitter"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>    
                                    <div className="w-full p-2 my-3">
                                        <h2 className="text-center text-xl font-bold">Datos de ubicación & Información adicional</h2>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                            <label htmlFor="address" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Dirección de la institución&nbsp;<span className="text-error">*</span></label>
                                            <input type="text" id="new-address" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                                autoComplete="nope"
                                                {...register("address", {
                                                    required: "La dirección del centro de atención es requerida"
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={ errors }
                                                name="address"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) }
                            <div className={ `p-2 w-full ${ preLoader !== null ? "" : "hidden" }` }>
                                <div className="relative mb-2">
                                    <div className="flex justify-center">
                                        <label htmlFor="coordinates_manual" className="flex items-center cursor-pointer relative">
                                            <input type="checkbox" id="coordinates_manual" 
                                                className="sr-only"
                                                {...register("coordinates_manual")}
                                                onChange={ useMap }
                                            />
                                            <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                            <span className="ml-3 text-gray-600 text-base font-medium">{ coordinatesManual === true ? "Ingresar coordenadas" : "Ubicar en el mapa" }</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={ `p-2 w-full ${ preLoader !== null ? "" : "hidden" } ${coordinatesManual === false ? "" :"hidden"}` }>
                                <div className="relative mb-2">
                                    <span className="leading-7 text-base text-gray-600 font-semibold block mb-1">Ubicación en el mapa&nbsp;<span className="text-error">*</span></span>
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full bg-white">
                                        <div id="new-center-map" className="w-full h-80" ref={ mapDiv } />
                                    </div>
                                </div>
                            </div>
                            <div className={ `p-2 xl:w-1/2 w-full ${coordinatesManual === true ? "" :"hidden"}` }>
                                <div className="relative mb-2">
                                    <label htmlFor="latitude" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Latitud&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="new-latitude" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                        autoComplete="nope"
                                        {...register("latitude", {
                                            required: "La latitud es requerida"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="latitude"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className={ `p-2 xl:w-1/2 w-full ${coordinatesManual === true ? "" :"hidden"}` }>
                                <div className="relative mb-2">
                                    <label htmlFor="longitude" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Longitud&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="new-longitude" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                        autoComplete="nope"
                                        {...register("longitude", {
                                            required: "La longitud es requerida"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="longitude"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className={ `p-2 w-full ${ preLoader !== null ? "" : "hidden" }` }>
                                <div className="relative mb-2 ck-editor-text-area">
                                    <label htmlFor="new_more_info" className="leading-7 text-base text-gray-600 font-semibold block mb-1">
                                        Información adicional&nbsp;<span className="text-error">*</span>
                                    </label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={ (data.more_info !== null && data.more_info !== undefined) ? data.more_info : "" }
                                        // data=""
                                        config={{
                                            toolbar: {
                                                items: [ 'heading', '|','bold', 'italic', '|','numberedList', 'bulletedList' ]
                                            }
                                        }}
                                        onChange={ (event, editor) => {
                                            // const data = editor;
                                            // console.log(editor);
                                            moreInfoCenter(editor);
                                        }}
                                    />
                                </div>
                            </div>
                            { preLoader === null && (
                                <svg className="animate-spin md:w-1/6 ms:w-2/6 w-3/6 p-6 text-secondary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) }                          
                        </div>
                        <div className={ `w-full pt-8 ${ preLoader !== null ? "flex" : "hidden" } justify-end` }>
                            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-theme font-bold text-white transition-colors hover:bg-dark-theme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-0 sm:w-auto sm:text-base">Actualizar</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default UpdateCenter;
