import axios from 'axios';
import Cookies from 'js-cookie';
// import { useRef, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link } from 'react-router-dom';

import { generateAlert, validateIcon } from '../../utils/utils';

const NewCategory = () => {
    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();

    const sendData = (data) => {
        let verifyIcon = validateIcon(data.file);
        
        let uri = `${process.env.REACT_APP_API_URI}category`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }
        
        if (verifyIcon) {
            data.icon = data.file[0];
            delete data.file;

            axios.post(uri, data, config)
            .then(response => {
                let result = {
                    type: 'success',
                    message: response.data.message
                };

                generateAlert(result);
                
                reset();
                clearErrors();
            })
            .catch(err => {
                console.log(err);
            });

            clearErrors();
        }
    };

    return (
        <div id="NewCategory">
            <div className="pt-5 flex items-center justify-center">
                <div className=" mx-auto text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-dark-theme sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </div>
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Nueva categoría</h3>
                </div>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6">
                <Link to="/categories" className="bg-white px-4 py-3 rounded-lg text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" >
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Regresar
                </Link>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <form id="new-category" onSubmit={ handleSubmit(sendData) } >
                        <div className="w-full mb-4">
                            <h2 className="text-base font-normal">Los campos con&nbsp;<span className="text-error">*</span>&nbsp;son requeridos.</h2>
                        </div>
                        <div className="flex flex-wrap -m-2">
                            <div className="p-2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="names" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nombre de la categoría&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                        autoComplete="nope"
                                        {...register("name", {
                                            required: "El nombre de la categoría es requerido"
                                        })}
                                        
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="name"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative mb-2 text-center">
                                    <span className="leading-7 text-base text-gray-600 font-semibold block mb-1">Icono de la categoría&nbsp;<span className="text-error">*</span></span>
                                    <label>
                                        <input type="file" id="file" className="text-base transition-colors file:mr-5 file:py-2 file:px-6 file:rounded-md file:border-0 file:text-base file:font-medium file:bg-body file:text-white hover:file:cursor-pointer hover:file:bg-theme"
                                            {...register("file", {
                                                required: "El icono es requerido"
                                            })}
                                        />
                                    </label>
                                    <div>
                                        <ErrorMessage
                                            errors={ errors }
                                            name="file"
                                            render={({ message }) => <span className="error-message">{ message }</span>}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="description" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Descripción de la categoría&nbsp;<span className="text-error">*</span></label>
                                    <textarea id="description" className="w-full h-32 resize-none bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                        {...register("description", {
                                            required: "La descripción de la categoría es requerida"
                                        })}
                                    ></textarea>
                                    <ErrorMessage
                                        errors={ errors }
                                        name="description"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full pt-8 flex justify-end">
                            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-theme font-bold text-white transition-colors hover:bg-dark-theme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-0 sm:w-auto sm:text-base">Ingresar</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default NewCategory;
