import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

export const paginationProps = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
};

export const generateAlert = (data) => {
    Swal.fire({
        title: data.message,
        icon: data.type,
        allowOutsideClick: false,
        confirmButtonText: 'OK'
    });
};

export const profileAdmin = async (navigate, path) => {
    let uri = `${process.env.REACT_APP_API_URI}auth/profile`;

    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${Cookies.get('Bearer')}`,
        }
    }
    
    if (Cookies.get('Bearer') !== undefined) {
        try {
            await axios.get(uri, config);

            if (path === '/') {
                navigate('/dashboard');
            } 
        } catch (error) {
            Cookies.remove('Bearer');
            Cookies.remove('Manager');

            navigate('/');
        }
    }
};

export const waitingResponse = () => {
    return (
        <>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </>
    );
};

export const sessionActive = (location, navigate) => {
    
    if (Cookies.get('Bearer') === undefined && location.pathname !== '/') {
        navigate('/');
    }
    
    if (Cookies.get('Bearer') !== undefined && location.pathname === '/') {
        navigate('/dashboard');
    }

    if (Cookies.get('Bearer') !== undefined && location.pathname !== '/') {
        profileAdmin(navigate, location.pathname);
    }
};

export const validateIcon = (file) => {
    let icon = file[0];
        
    if (file.length < 1) {
        generateAlert({
            type: 'error',
            message: 'El icono del servicio es requerido'
        });

        return false;
    }

    if (icon.type !== 'image/png') {
        generateAlert({
            type: 'error',
            message: 'El formato de icono debe ser PNG'
        });

        return false;
    }

    // Agregar validación de medidas para cuando se tengan

    return true;
};

export const validatePhoto = (file) => {
    let icon = file[0];

    if (icon.type !== 'image/png' && icon.type !== 'image/jpeg') {
        generateAlert({
            type: 'error',
            message: 'El formato de la foto debe ser PNG o JPEG/JPG'
        });

        return false;
    }

    // Agregar validación de medidas para cuando se tengan

    return true;
};

export const LoadingTable = () => {
    return (
        <div className="h-20">
            asdasdasdasd
        </div>
    );
};
