import axios from 'axios';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import { sessionActive } from '../../utils/utils';

import '../../../assets/css/Dashboard.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const Home = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        centers: 0,
        services: 0,
        records: 0,
    });
    const [chartData, setChartData] = useState(null);

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}record/dashboard`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            // console.log(response.data);
            let labels = [];
            let dataChart = [];

            response.data.searchs.map(item => {
                labels.push(item.category);
                dataChart.push(item.quantity);  

                return null;
            });

            let defaultData = {
                labels: labels,
                datasets: [
                    {
                        label: " busquedas en ",
                        data: dataChart,
                        backgroundColor: [
                            '#1393CA',
                            '#455c67',
                            '#18D6EC',
                        ],
                        borderWidth: 0,
                    },
                ],
            };

            setChartData(defaultData);
            setData(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    }, []);

    const ops = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 0,
                bottom: 0
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    font: {
                        size: 16,
                        weight: '600'
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let data = context.raw;
                        let label = context.dataset.label || '';
                        
                        let tooltip = "";
                        // console.log(context);
                        if (label) {
                            tooltip += ' ' + data;
                            label += context.label;
                            tooltip += label;
                        }

                        return tooltip;
                    }
                }
            }
        }
    };

    useEffect(() => {
        sessionActive(location, navigate);

        if (isLoading === false) {
            loadData();
        }
        
    }, [location, navigate, isLoading, loadData]);

    return (
        <div id="Dashboard">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold">
                    BienEStar Escolar
                </h1>
                <br />
                <p className="text-lg">Revisa la última información</p>
            </div>
            <div className="flex flex-wrap mb-12 mt-6">
                <div className="w-full md:w-1/2 xl:w-1/3 px-3">
                    <div className="report-card">
                        <svg viewBox="0 0 20 20" className="w-10 h-10 fill-current mr-4 icon-card">
                            <path d="M17.35,2.219h-5.934c-0.115,0-0.225,0.045-0.307,0.128l-8.762,8.762c-0.171,0.168-0.171,0.443,0,0.611l5.933,5.934c0.167,0.171,0.443,0.169,0.612,0l8.762-8.763c0.083-0.083,0.128-0.192,0.128-0.307V2.651C17.781,2.414,17.587,2.219,17.35,2.219M16.916,8.405l-8.332,8.332l-5.321-5.321l8.333-8.332h5.32V8.405z M13.891,4.367c-0.957,0-1.729,0.772-1.729,1.729c0,0.957,0.771,1.729,1.729,1.729s1.729-0.772,1.729-1.729C15.619,5.14,14.848,4.367,13.891,4.367 M14.502,6.708c-0.326,0.326-0.896,0.326-1.223,0c-0.338-0.342-0.338-0.882,0-1.224c0.342-0.337,0.881-0.337,1.223,0C14.84,5.826,14.84,6.366,14.502,6.708"></path>
                        </svg>
                        <div className="text-white contet-report">
                            <p className="font-semibold text-2xl">{ data.centers }</p>
                            <p className="text-sm">Instituciones registradas</p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 xl:w-1/3 px-3">
                    <div className="report-card">
                        <svg viewBox="0 0 20 20" className="w-10 h-10 fill-current mr-4 icon-card">
                            <path d="M17.35,2.219h-5.934c-0.115,0-0.225,0.045-0.307,0.128l-8.762,8.762c-0.171,0.168-0.171,0.443,0,0.611l5.933,5.934c0.167,0.171,0.443,0.169,0.612,0l8.762-8.763c0.083-0.083,0.128-0.192,0.128-0.307V2.651C17.781,2.414,17.587,2.219,17.35,2.219M16.916,8.405l-8.332,8.332l-5.321-5.321l8.333-8.332h5.32V8.405z M13.891,4.367c-0.957,0-1.729,0.772-1.729,1.729c0,0.957,0.771,1.729,1.729,1.729s1.729-0.772,1.729-1.729C15.619,5.14,14.848,4.367,13.891,4.367 M14.502,6.708c-0.326,0.326-0.896,0.326-1.223,0c-0.338-0.342-0.338-0.882,0-1.224c0.342-0.337,0.881-0.337,1.223,0C14.84,5.826,14.84,6.366,14.502,6.708"></path>
                        </svg>
                        <div className="text-white contet-report">
                            <p className="font-semibold text-2xl">{ data.services }</p>
                            <p className="text-sm">Servicios registrados</p>
                        </div>
                    </div>
                </div>
                <div className="w-full xl:w-1/3 px-3">
                    <div className="report-card">
                        <svg viewBox="0 0 20 20" className="w-10 h-10 fill-current mr-4 icon-card">
                            <path d="M17.35,2.219h-5.934c-0.115,0-0.225,0.045-0.307,0.128l-8.762,8.762c-0.171,0.168-0.171,0.443,0,0.611l5.933,5.934c0.167,0.171,0.443,0.169,0.612,0l8.762-8.763c0.083-0.083,0.128-0.192,0.128-0.307V2.651C17.781,2.414,17.587,2.219,17.35,2.219M16.916,8.405l-8.332,8.332l-5.321-5.321l8.333-8.332h5.32V8.405z M13.891,4.367c-0.957,0-1.729,0.772-1.729,1.729c0,0.957,0.771,1.729,1.729,1.729s1.729-0.772,1.729-1.729C15.619,5.14,14.848,4.367,13.891,4.367 M14.502,6.708c-0.326,0.326-0.896,0.326-1.223,0c-0.338-0.342-0.338-0.882,0-1.224c0.342-0.337,0.881-0.337,1.223,0C14.84,5.826,14.84,6.366,14.502,6.708"></path>
                        </svg>
                        <div className="text-white contet-report">
                            <p className="font-semibold text-2xl">{ data.records }</p>
                            <p className="text-sm">Total de búsquedas</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-6">
                <div className="admin-card bg-white shadow-lg">
                    <div className="text-center mt-5 mb-3">
                        <h2 className="text-xl font-bold">Total de busquedas por categoría</h2>
                    </div>                        
                    <div className="flex justify-center p-4 w-full h-96 mb-3">
                        { chartData && (
                            <Pie
                                data={ chartData }
                                options={ ops }
                            />
                        ) }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
