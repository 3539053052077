import axios from 'axios';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { generateAlert } from '../../utils/utils';

import Image from '../../../assets/images/change-password.png';

const PasswordForm = () => {
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const loadData = useCallback(() => {
        clearErrors();
        setIsLoading(true);
    }, [clearErrors]);

    const updatePassword = (data) => {
        let uri = `${process.env.REACT_APP_API_URI}auth/change-password`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.put(uri, JSON.stringify(data), config)
        .then(response => {
            let result = {
                type: 'success',
                message: response.data.message
            };

            generateAlert(result);
            reset();
        })
        .catch(err => {
            let data = {
                message: err.response.data.message,
                type: 'error'
            }
            
            generateAlert(data);
        });

        clearErrors();
    };

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, [isLoading, loadData]);

    return (
        <div className="container">
            <div className="container mx-auto flex flex-wrap">
                <div className="xl:w-1/3 lg:w-1/2 w-full h-full">
                    <div className="flex flex-col">
                        <div className="rounded-lg shadow-md bg-white h-full m-4 px-4 py-6">
                            <div className="h-full flex flex-col items-center justify-center text-center">
                                <img 
                                    src={ Image }
                                    className="rounded-full w-1/3 object-cover object-center my-5" 
                                    alt="" 
                                />
                                {/* <div className="w-full text-center flex justify-center">
                                    <div>
                                        dfgdfg
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:w-2/3 lg:w-1/2 w-full overflow-hidden h-full">
                    <div className="flex items-center justify-start relative w-full">
                        <div className="rounded-lg shadow-md bg-white m-4 w-full h-full">
                            <div className="text-start text-dark p-4 border-b border-muted">
                                <h2 className="text-2xl font-bold">Cambiar mis credenciales</h2>
                            </div>
                            <div className="p-6">
                                {/* { data.names && ( */}
                                    <form id="password-form" onSubmit={ handleSubmit(updatePassword) } >
                                        <div className="flex flex-wrap -m-2">
                                            <div className="p-2 w-full">
                                                <div className="relative mb-2">
                                                    <label htmlFor="password" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Ingrese su clave actual</label>
                                                    <input type="password" id="password" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                                        {...register("password", {
                                                            required: "Su clave actual es requerida"
                                                        })}
                                                    />
                                                    <ErrorMessage
                                                        errors={ errors }
                                                        name="password"
                                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-2 xl:w-1/2 w-full">
                                                <div className="relative mb-2">
                                                    <label htmlFor="new" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Ingrese su nueva clave</label>
                                                    <input type="password" id="new" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                                        {...register("new", {
                                                            required: "Su nueva clave es requerida"
                                                        })}
                                                    />
                                                    <ErrorMessage
                                                        errors={ errors }
                                                        name="new"
                                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-2 xl:w-1/2 w-full">
                                                <div className="relative mb-2">
                                                    <label htmlFor="confirm" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Confirme su nueva clave</label>
                                                    <input type="password" id="confirm" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                                        {...register("confirm", {
                                                            validate: (value) => {
                                                                if (value !== getValues('new')) {
                                                                    return "Las claves no coinciden";
                                                                }
                                                            },
                                                            required: "La confirmación de su nueva clave es requerida"
                                                        })}
                                                    />
                                                    <ErrorMessage
                                                        errors={ errors }
                                                        name="confirm"
                                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-2 w-full mt-2">
                                                <button type="submit" className="flex mx-auto text-white transition-colors bg-theme border-0 py-2 px-8 focus:outline-none hover:bg-dark-theme rounded-md text-lg" >Actualizar mis credenciales</button>
                                            </div>
                                        </div>
                                    </form>
                                {/*) }*/}
                                {/* { !data.names && (
                                    <div className="flex justify-center">
                                        <svg className="animate-spin md:w-1/6 ms:w-2/6 w-3/6 p-6 text-secondary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                ) } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordForm;
