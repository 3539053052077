import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProfileForm from '../../content/Profile/ProfileForm';
import PasswordForm from '../../content/Profile/PasswordForm';

import { sessionActive } from '../../utils/utils';

import '../../../assets/css/Profile.css';

const Profile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    // const [isLoading, setIsLoading] = useState(false);
    const [profileActive, setProfileActive] = useState(true);

    useEffect(() => {
        sessionActive(location, navigate);

        // if (isLoading === false) {
            // blockActions();
        // }
        
    }, [location, navigate]);

    return (
        <div id="Profile">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold">
                   Perfil
                </h1>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-center mt-6">
                <button 
                    className={ `tab-button ${ profileActive ? "tab-button-active" : "" }` } 
                    onClick={ () => setProfileActive(true) }
                >
                    <FontAwesomeIcon className="w-5 h-5 mr-3" icon="fa-solid fa-clipboard-user" />Información
                </button>
                <button 
                    className={ `tab-button ${ !profileActive ? "tab-button-active" : "" }` } 
                    onClick={ () => setProfileActive(false) }
                >
                    <FontAwesomeIcon className="w-5 h-5 mr-3" icon="fa-solid fa-lock" />Crendenciales
                </button>
            </div>
            <section id="info-profile" className={ !profileActive ? "hidden" : "block" }>
                <ProfileForm />
            </section>
            <section id="password-profile" className={ profileActive ? "hidden" : "block" }>
                { profileActive === false && (
                    <PasswordForm />
                ) }
            </section>
        </div>
    );
};

export default Profile;
