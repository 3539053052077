import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState, createRef, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ReactToPrint from 'react-to-print';
import exportFromJSON from 'export-from-json';

ChartJS.register(ArcElement, Tooltip, Legend);

const reportRef = createRef();
const date = new Date();

const StatesReport = () => {
    
    const onBeforeGetContentResolve = useRef(null);

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [preLoader, setPreloader] = useState(null);

    const ops = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 0,
                bottom: 0
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    font: {
                        size: 16,
                        weight: '600'
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let data = context.raw;
                        let label = context.dataset.label || '';
                        
                        let tooltip = "";
                        // console.log(context);
                        if (label) {
                            tooltip += ' ' + data;
                            label += context.label;
                            tooltip += label;
                        }

                        return tooltip;
                    }
                }
            }
        }
    };

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}record/states`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {            
            let labels = [];
            let dataChart = [];

            for (let i = 0; i < 5; i++) {
                try {
                    labels.push(response.data[i].state);
                    dataChart.push(response.data[i].quantity);
                } catch (error) {
                    console.log(error);
                    continue;
                }
            }

            let defaultData = {
                labels: labels,
                datasets: [
                    {
                        label: ": ",
                        data: dataChart,
                        backgroundColor: [
                            '#ff6384',
                            '#36a2eb',
                            '#cc65fe',
                            '#ffce56',
                            '#77DD77'
                        ],
                        borderWidth: 0,
                    },
                ],
            };
            
            setChartData(defaultData);
            setData(response.data);
            setPreloader(true);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    }, []);

    const generatePdfFile = useCallback(() => {
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                resolve();
            }, 2000);
        });
    }, []);

    const generateFileExcel = () => {  
        if (Array.isArray(data)) {
            let info = [];

            for (let i = 0; i < data.length; i++) {
                info.push({
                    "N°": (i + 1),
                    "Identificador": data[i].id,
                    "Departamento": data[i].state,
                    "Total de busquedas": data[i].quantity,
                });
            }
            
            const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-departamentos`;
            const exportType =  exportFromJSON.types.xls;
            
            exportFromJSON({ data: info, fileName: fileName, exportType: exportType });
        }
    };

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, [isLoading, loadData]);

    return (
        <div id="CategoriesReport">
            <div className="flex mx-auto flex-wrap justify-between mb-10 mt-6 w-full">
                <div className="flex flex-wrap justify-start">
                    <Link to="/reports" className="bg-white px-4 py-3 rounded-lg text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" >
                        <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Regresar
                    </Link>
                </div>
                <div className="flex flex-wrap justify-start">
                    <ReactToPrint
                        content={ () => reportRef.current }
                        onBeforeGetContent={ generatePdfFile }
                        trigger={ () => <button className="bg-pdf px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-md text-base text-white transition-colors hover:bg-dark-pdf disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" disabled={ data.length > 0 ? false : true }>
                            <FontAwesomeIcon className="mr-3" icon="fa-solid fa-file-pdf" />Generar archivo PDF
                        </button> }
                    />
                    {/* <Pdf targetRef={ reportRef } filename={ `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-categorias.pdf` }>
                        {({ toPdf }) => <button className="bg-pdf px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-md text-base text-white transition-colors hover:bg-dark-pdf disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" disabled={ data.length > 0 ? false : true } onClick={ toPdf } >
                            <FontAwesomeIcon className="mr-3" icon="fa-solid fa-file-pdf" />Generar archivo PDF
                        </button>}
                    </Pdf> */}
                    <button className="bg-excel px-4 py-3 md:mb-0 mb-3 rounded-md text-base text-white transition-colors hover:bg-dark-excel disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" onClick={ generateFileExcel } disabled={ data.length > 0 ? false : true }>
                        <FontAwesomeIcon className="mr-3" icon="fa-solid fa-file-excel" />Generar archivo Excel
                    </button>
                </div>
            </div>
            <section className="w-full flex justify-center">
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg w-2/3">
                    <div className="mx-auto bg-white" ref={ reportRef } >
                        { preLoader !== null && (
                            <div className="my-8 bg-white">
                                <div className="text-center">
                                    <h2 className="text-2xl font-bold">Departamentos con más busquedas</h2>
                                </div>
                                <br />
                                <div className="h-80 my-5">
                                    <Pie data={ chartData } options={ ops } />
                                </div>
                                <div className="text-center mt-5">
                                    <div className="my-5 flex justify-center table-auto">
                                        <table className="w-1/2 border-collapse border border-slate-600">
                                            <thead className="bg-slate-600 text-white">
                                                <tr>
                                                    <th className="px-4 py-2 border border-slate-600">#</th>
                                                    <th className="px-4 py-2 border border-slate-600">Departamento</th>
                                                    <th className="px-4 py-2 border border-slate-600">Total de busquedas</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-dark">
                                                { data.map((item, index) => {
                                                    if (index < 5) {
                                                        return (
                                                            <tr key={ index }>
                                                                <td className="px-4 py-2 border border-slate-600">
                                                                    <strong>{ index + 1 }</strong>
                                                                </td>
                                                                <td className="px-4 py-2 border border-slate-600">{ item.state }</td>
                                                                <td className="px-4 py-2 border border-slate-600">
                                                                    <strong className="text-theme">{ item.quantity }</strong>
                                                                </td>
                                                            </tr>
                                                        );
                                                    } else {
                                                        return null;
                                                    }                                                    
                                                }) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="text-center font-normal">
                                    <span>Informe generado él <i>{ date.getDay() }-{ date.getMonth() }-{ date.getFullYear() }</i></span>
                                </div>
                            </div>
                        ) }
                        { preLoader === null && (
                            <div className="w-full flex justify-center">
                                <svg className="animate-spin md:w-1/6 ms:w-2/6 w-3/6 p-6 text-secondary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        ) }
                    </div>  
                </div>
            </section>
        </div>
    );
};

export default StatesReport;
