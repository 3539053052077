import axios from 'axios';
import Cookies from 'js-cookie';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { sessionActive, paginationProps } from '../../utils/utils';

const Schedules = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const columns = [
        {
            name: '#',
            width: "65px",
            cell: (row) => <b>{ row.id }</b>
        },
        {
            name: 'Centro',
            width: "250px",
            cell: (row) => <div className="group">
                <span>{ `${(row.center).slice(0, 20)}` }{ row.center.length > 20 ? "..." : "" }</span>
                {/* { row.name.length > 20 && (
                    <div class="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                        <span class="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.center }</span>
                    </div>
                ) } */}
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.center }</span>
                </div>
            </div>
        },
        {
            name: 'Departamento',
            width: "200px",
            cell: (row) => <span>{ row.state }</span>
        },
        
        {
            name: 'Municipio',
            width: "200px",
            cell: (row) => <span>{ row.city }</span>
        },
        {
            name: 'Atención',
            width: "120px",
            cell: (row) => <span>{ row.days > 1 ? `${row.days} días` : `${row.days} día` }</span>
        },
        {
            name: 'Ver / Editar',
            cell: (row) => <div>
                <Link to={ `/attention-schedules/${row.id}` } className="edit-button bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-theme" >
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </Link>
            </div>
        }
    ];

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}attention-schedule`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            setData(response.data);
        })
        .catch(err => {
            console.log(err);
        });
        
        setIsLoading(true);
    }, []);

    useEffect(() => {
        sessionActive(location, navigate);

        if (isLoading === false) {
            loadData();
        }

    }, [isLoading, location, navigate, loadData]);

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold">
                   Horarios de atención
                </h1>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6 w-full">
                <Link to="/centers" className="bg-white px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-md text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" disabled={ data.length > 0 ? false : true }>
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-house-medical" />Listado de instituciones
                </Link>
                <Link to="/attention-schedules/new" className="bg-theme px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-theme disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full" disabled={ data.length > 0 ? false : true }>
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-pen" />Nuevo horario de atención
                </Link>
            </div>
            <section className="table-results">
                <div className="table-auto">
                <DataTableExtensions
                        columns={ columns }
                        data={ data }
                        filterPlaceholder="Buscar..."
                        print={ false }
                        export={ false }
                    >
                        <DataTable
                            pagination
                            columns={ columns }
                            data={ data }
                            dense
                            striped
                            responsive
                            paginationComponentOptions={ paginationProps }
                        />
                    </DataTableExtensions>
                </div>
            </section>
        </div>
    );
};

export default Schedules;
