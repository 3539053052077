import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { generateAlert } from '../utils/utils';

import Icon from '../../assets/images/icon.png';

import Menus from '../../data/menu.json';

const IconToggle = styled.button`
    background: rgb(30, 41, 59);
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before,
    &::after {
        content: "";
        background-color: #fff;
        height: 3px;
        width: 1.5rem;
        position: absolute;
        transition: all 0.3s ease;
    }
    &::before {
        top: ${(props) => (props.clicked ? "1.5" : "1rem")};
        transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
    }
    &::after {
        top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
        transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
    }
`;

const Sidebar = () => {
    const [open, setOpen] = useState(false);
    const { width } = useWindowDimensions();

    const navigate = useNavigate();
    
    const logOut = (e) => {
        e.preventDefault();

        let uri = `${process.env.REACT_APP_API_URI}auth/logout`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }
        
        Swal.fire({
            title: 'Cerrando sesión...',
            text: "¿Esta seguro de querer salir?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, quiero salir!',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.get(uri, config)
                .then(response => {
                    Cookies.remove('Bearer');
                    Cookies.remove('Manager');
                    
                    navigate('/');
                })
                .catch(err => {
                    let data = {
                        message: `Error al intentar cerrar sesión. (${err.response.data.message})`,
                        type: 'error'
                    }
                    
                    generateAlert(data);
                });
            }
        });
    };

    return (
        <>
            { width <= 640 && (
                <div className="fixed z-20">
                    <div className="flex justify-center p-3">
                        <IconToggle clicked={ open } onClick={() => setOpen(!open)} />
                    </div>
                </div>
            ) }
            <div
                className={ `${ open ? "w-64" : "sm:w-20 w-0" } bg-theme h-screen fixed duration-300 z-30` }
            >
                <div className={ `${ open ? "" : "sm:visible invisible" } flex justify-center py-3` } >
                    <IconToggle clicked={ open } onClick={() => setOpen(!open)} />
                </div>
                <div className="flex gap-x-4 items-center px-5 pt-5 pb-3">
                    <img
                        // src="./src/assets/logo.png"
                        src={ Icon }
                        alt=""
                        className={`rounded-md duration-500 ${ open && "rotate-[360deg]" }`}
                    />
                    <h1
                        className={ `text-white origin-left font-medium text-xl duration-200 ${ !open && "scale-0" }` }
                    >
                        Administración
                    </h1>
                </div>
                <ul className="pt-6 px-3">
                    { Menus.map((menu, index) => {
                        if (menu.slug === 'users') {
                            if (Cookies.get('Manager') !== undefined && Cookies.get('Manager') === '1') {
                                return (
                                    <li key={ index } >
                                        <Link to={ `/${menu.slug}` } className="item-sidebar"  onClick={() => setOpen(false)} >
                                            <FontAwesomeIcon 
                                                className={ open ? "" : "sm:inline hidden" }
                                                icon={ `fa-solid ${menu.icon}` }
                                            />
                                            <span className={`${ !open && "scale-0" } origin-left duration-200 whitespace-nowrap `}>
                                                { menu.title }
                                            </span>
                                        </Link>
                                    </li>
                                ); 
                            } else {
                                return null;
                            }
                        } else {
                            return (
                                <li key={ index } >
                                    <Link to={ `/${menu.slug}` } className="item-sidebar"  onClick={() => setOpen(false)} >
                                        <FontAwesomeIcon 
                                            className={ open ? "" : "sm:inline hidden" }
                                            icon={ `fa-solid ${menu.icon}` }
                                        />
                                        <span className={`${ !open && "scale-0" } origin-left duration-200 whitespace-nowrap `}>
                                            { menu.title }
                                        </span>
                                    </Link>
                                </li>
                            );
                        }
                    }) }
                    <li className="item-sidebar" onClick={ logOut } >
                        <FontAwesomeIcon
                            className={ open ? "" : "sm:inline hidden" }
                            icon={ `fas-solid fa-power-off` }
                        />
                        <span className={ `${!open && "scale-0"} origin-left duration-200 whitespace-nowrap ` }>Cerrar sesión</span>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Sidebar;
