import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { sessionActive, generateAlert, paginationProps } from '../../utils/utils';

const Users = () => { 
    const location = useLocation();
    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const columns = [
        {
            name: '#',
            width: "65px",
            cell: (row) => <b>{ row.id }</b>
        },
        {
            name: 'Nombres',
            width: "200px",
            cell: (row) => <span>{ row.names }</span>
        },
        {
            name: 'Apellidos',
            width: "200px",
            cell: (row) => <span>{ row.lastnames }</span>
        },
        {
            name: 'Usuario',
            width: "150px",
            cell: (row) => <span>{ row.username }</span>
        },
        {
            name: 'Tipo',
            width: "220px",
            cell: (row) => <span>{ row.is_admin === 1 ? "Administrador" : "Editor" }</span>
        },
        {
            name: 'Activo',
            width: "90px",
            cell: (row) => <div>{ row.status === 1 ?  <FontAwesomeIcon icon="fa-solid fa-circle-check" className="text-green-600" /> : <FontAwesomeIcon icon="fa-solid fa-circle-xmark" className="text-error" /> }</div>
        },
        {
            name: 'Ver / Editar',
            cell: (row, i) => <div>
                <Link to={ `/users/${row.id}` } className="edit-button bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-theme" >
                    <FontAwesomeIcon icon="fa-solid fa-user-pen" />
                </Link>
            </div>
        },
        {
            name: 'Borrar',
            cell: (row) => <div>
                <button data-uuid={ row.id } className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700" onClick={ deleteUser } >
                    <FontAwesomeIcon icon="fa-solid fa-user-xmark" />
                </button>
            </div>
        }
    ];

    const removeUser = (id) => {
        let uri = `${process.env.REACT_APP_API_URI}user/${id}`;
        // console.log(id);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.delete(uri, config)
        .then(response => {
            loadData();

            let result = {
                type: 'success',
                message: response.data.message
            };
            generateAlert(result);
        })
        .catch(err => {
            console.log(err);
        })
    };

    const deleteUser = (e) => {
        let uuid = e.target.dataset.uuid;
        // console.log(uuid);
        Swal.fire({
            title: 'Borrando usuario...',
            text: "¿Esta seguro de querer borrar este usuario?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {
            if (result.isConfirmed) {
                removeUser(uuid);
            }
        });
    };

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}user`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            setData(response.data);
        })
        .catch(err => {
            console.log(err);
        });

        setIsLoading(true);
    }, [setIsLoading, setData]);

    useEffect(() => {
        sessionActive(location, navigate);

        if (isLoading === false) {
            loadData();
        }
        
    }, [location, navigate, isLoading, loadData]);

    return (
        <div id="Users">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold">
                   Usuarios
                </h1>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6">
                <Link to="/users/new" className="bg-theme px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-theme disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" disabled={ data.length > 0 ? false : true } >
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-user-plus" />Nuevo usuario
                </Link>
            </div>
            <section className="table-results">
                <div className="table-auto" >
                    <DataTableExtensions
                        columns={ columns }
                        data={ data }
                        filterPlaceholder="Buscar..."
                        print={ false }
                        export={ false }
                    >
                        <DataTable
                            pagination
                            columns={ columns }
                            data={ data }
                            dense
                            striped
                            responsive
                            paginationComponentOptions={ paginationProps }
                        />
                    </DataTableExtensions>
                </div>
            </section>
        </div>
    );
};

export default Users;
