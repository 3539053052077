import axios from 'axios';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { generateAlert } from '../../utils/utils';

const UpdateUser = () => {
    const { id } = useParams();
    // const location = useLocation();
    const navigate = useNavigate();

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const loadData = useCallback(() => {       
        if (id !== null || id !== undefined) {
            let uri = `${process.env.REACT_APP_API_URI}user/${id}`;
    
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('Bearer')}`,
                }
            }
            
            axios.get(uri, config)
            .then(response => {
                let defaultValues = {
                    names: response.data.names,
                    lastnames: response.data.lastnames,
                    email: response.data.email,
                    is_admin: (response.data.is_admin).toString(),
                    status: response.data.status
                }

                reset(defaultValues);
                setIsLoading(true);
            })
            .catch(err => {
                console.log(err);
            });
        }
    }, [id, reset]);

    const sendData = (data) => {
        let uri = `${process.env.REACT_APP_API_URI}user/${id}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.put(uri, JSON.stringify(data), config)
        .then(response => {

            let result = {
                type: 'success',
                message: response.data.message
            };

            generateAlert(result);
            
            reset();
            clearErrors();
            navigate("/users");
        })
        .catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, [loadData, isLoading]);

    return (
        <div id="UpdateUser">
            <div className="pt-5 flex items-center justify-center">
                <div className=" mx-auto text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-dark-theme sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </div>
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Actualizando usuario</h3>
                </div>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6">
                <Link to="/users" className="bg-white px-4 py-3 rounded-lg text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" >
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Regresar
                </Link>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <form id="update-user" onSubmit={ handleSubmit(sendData) } >
                        <div className="w-full mb-4">
                            <h2 className="text-base font-normal">Los campos con&nbsp;<span className="text-error">*</span>&nbsp;son requeridos.</h2>
                        </div>
                        <div className="flex flex-wrap -m-2">
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="u-names" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nombres&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="u-names" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                        {...register("names", {
                                            required: "Los nombres son requeridos"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="names"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="u-lastnames" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Apellidos&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="u-lastnames" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                        {...register("lastnames", {
                                            required: "Los apellidos son requeridos"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="lastnames"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="u-email" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Correo electrónico&nbsp;<span className="text-error">*</span></label>
                                    <input type="email" id="u-email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                        {...register("email", {
                                            required: "El correo electrónico es requerido",
                                            pattern: {
                                                value: pattern,
                                                message: "El correo electrónico es inválido"
                                            }
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="email"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative mb-2 text-center">
                                    <label htmlFor="is_admin" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nivel de usuario&nbsp;<span className="text-error">*</span></label>
                                </div>
                                <div className="flex flex-wrap mt-4 max-w-md mx-auto">
                                    <div className="w-full sm:w-1/2">
                                        <div className="relative m-2">
                                            <input 
                                                className="sr-only peer" 
                                                type="radio" 
                                                id="u-is_admin" 
                                                value={ 1 } 
                                                {...register("is_admin", { 
                                                    required: "Seleccione una opción"
                                                })}
                                            />
                                            <label className="radio-form" htmlFor="u-is_admin">Administrador</label>
                                            <div className="absolute hidden w-5 h-5 peer-checked:block right-3 top-1/3">
                                                <FontAwesomeIcon className="text-white" icon="fa-solid fa-circle-check" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/2">
                                        <div className="relative m-2">
                                            <input 
                                                className="sr-only peer" 
                                                type="radio" 
                                                id="u-not_admin" 
                                                value={ 0 } 
                                                {...register("is_admin", { 
                                                    required: "Seleccione una opción"
                                                })}
                                            />
                                            <label className="radio-form" htmlFor="u-not_admin">Supervisor</label>
                                            <div className="absolute hidden w-5 h-5 peer-checked:block right-3 top-1/3">
                                                <FontAwesomeIcon className="text-white" icon="fa-solid fa-circle-check" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-3">
                                    <ErrorMessage
                                        errors={ errors }
                                        name="is_admin"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="u-status" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Estado&nbsp;<span className="text-error">*</span></label>
                                    <select id="u-status" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    {...register("status", {
                                        required: "Seleccione un estado"
                                    })} >
                                        <option value={ 1 }>Activo</option>
                                        <option value={ 2 }>Inactivo</option>
                                    </select>
                                    <ErrorMessage
                                        errors={ errors }
                                        name="status"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full pt-8 flex justify-end">
                            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-theme font-bold text-white transition-colors hover:bg-dark-theme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-0 sm:w-auto sm:text-base">Actualizar</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default UpdateUser;
