import axios from 'axios';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { generateAlert } from '../../utils/utils';

import AvatarMale from '../../../assets/images/user-male.png';

const ProfileForm = () => {
    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}auth/profile`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            let res = response.data;
            
            let defaultData = {
                // id: res.id,
                names: res.names,
                lastnames: res.lastnames,
                username: res.username,
                email: res.email,
            }

            reset(defaultData);
            setData(res);
        })
        .catch(err => {
            console.log(err);
        });

        setIsLoading(true);
    }, [setIsLoading, reset]);

    const updateProfile = (data) => {
        let uri = `${process.env.REACT_APP_API_URI}auth/profile/${data.username}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.put(uri, JSON.stringify(data), config)
        .then(response => {
            loadData();

            let result = {
                type: 'success',
                message: response.data.message
            };

            generateAlert(result);
        })
        .catch(err => {
            console.log(err);
        });

        clearErrors();
    };

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, [isLoading, loadData]);

    return (
        <div className="container">
            <div className="container mx-auto flex flex-wrap">
                <div className="xl:w-2/3 lg:w-1/2 w-full overflow-hidden h-full">
                    <div className="flex items-center justify-start relative w-full">
                        <div className="rounded-lg shadow-md bg-white m-4 w-full h-full">
                            <div className="text-start text-dark p-4 border-b border-muted">
                                <h2 className="text-2xl font-bold">Mi información</h2>
                            </div>
                            <div className="p-6">
                                { data.names && (
                                    <form id="profile-form" onSubmit={ handleSubmit(updateProfile) } >
                                        <div className="flex flex-wrap -m-2">
                                            <div className="p-2 xl:w-1/2 w-full">
                                                <div className="relative mb-2">
                                                    <label htmlFor="names" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nombres</label>
                                                    <input type="text" id="names" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                                        {...register("names", {
                                                            required: "Los nombres son requeridos"
                                                        })}
                                                    />
                                                    <ErrorMessage
                                                        errors={ errors }
                                                        name="names"
                                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-2 xl:w-1/2 w-full">
                                                <div className="relative mb-2">
                                                    <label htmlFor="lastnames" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Apellidos</label>
                                                    <input type="text" id="lastnames" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                                        {...register("lastnames", {
                                                            required: "Los apellidos son requeridos"
                                                        })}
                                                    />
                                                    <ErrorMessage
                                                        errors={ errors }
                                                        name="lastnames"
                                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-2 xl:w-1/2 w-full">
                                                <div className="relative mb-2">
                                                    <label htmlFor="username" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Usuario</label>
                                                    <input type="text" id="username" className="w-full bg-gray-300 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope" readOnly={ true }
                                                        {...register("username")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-2 xl:w-1/2 w-full">
                                                <div className="relative mb-2">
                                                    <label htmlFor="email" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Correo electrónico</label>
                                                    <input type="email" id="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                                        {...register("email", {
                                                            required: "El correo electrónico es requerido",
                                                            pattern: {
                                                                value: pattern,
                                                                message: "El correo electrónico es inválido"
                                                            }
                                                        })}
                                                    />
                                                    <ErrorMessage
                                                        errors={ errors }
                                                        name="email"
                                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-2 w-full mt-2">
                                                <button type="submit" className="flex mx-auto text-white transition-colors bg-theme border-0 py-2 px-8 focus:outline-none hover:bg-dark-theme rounded-md text-lg" >Actualizar mi información</button>
                                            </div>
                                        </div>
                                    </form>
                                ) }
                                { !data.names && (
                                    <div className="flex justify-center">
                                        <svg className="animate-spin md:w-1/6 ms:w-2/6 w-3/6 p-6 text-secondary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:w-1/3 lg:w-1/2 w-full h-full">
                    <div className="flex flex-col">
                        <div className="rounded-lg shadow-md bg-white h-full m-4 px-4 py-6">
                            <div className="h-full flex flex-col items-center justify-center text-center">
                                <img 
                                    src={ AvatarMale }
                                    className="rounded-full w-1/3 object-cover object-center mb-5 shadow" 
                                    alt="" 
                                />
                                <div className="w-full text-center flex flex-col justify-center items-center">
                                    <h2 className={ `text-center break-all block font-bold text-lg mb-1 text-dark-theme ${ (!data.names && !data.lastnames) ? "bg-gray-200 w-2/3 rounded-md animate-pulse" : "" }` }>
                                        { (data.names && data.lastnames) && (
                                            <>{ data.names }&nbsp;{ data.lastnames }</>
                                        )  }
                                        { (!data.names && !data.lastnames) && (
                                            <br />
                                        )  }
                                    </h2>
                                    <h3 className={ `text-center break-all block text-dark mb-4 font-semibold text-base ${ (!data.is_admin) ? "bg-gray-200 w-1/3 rounded-md animate-pulse" : "" }` }>
                                        { (data.is_admin) && (
                                            <>{ data.is_admin === 1 ? "Administrador" : "Supervisor" }</>
                                        )  }
                                        { (!data.is_admin) && (
                                            <br />
                                        )  }
                                    </h3>
                                    <h2 className={ `text-center break-all block mb-4 font-bold text-lg text-dark-theme ${ !data.username ? "bg-gray-200 w-2/4 rounded-md animate-pulse" : "" }` }>
                                        { (data.username) && (
                                            <>{ data.username }</>
                                        )  }
                                        { (!data.username) && (
                                            <br />
                                        )  }
                                    </h2>
                                    <h2 className={ `text-center break-all block font-bold text-lg text-dark-theme ${ !data.email ? "bg-gray-200 w-2/4 rounded-md animate-pulse" : "" }` }>
                                        { (data.email) && (
                                            <>{ data.email }</>
                                        )  }
                                        { (!data.email) && (
                                            <br />
                                        )  }
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileForm;
