import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { generateAlert } from '../../utils/utils';

const UpdateService = () => {
    const { id } = useParams();
    // const location = useLocation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    // const [preLoader, setPreloader] = useState(null);
    // const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);

    const loadCategories = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}category`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        axios.get(uri, config)
        .then(response => {
            // console.log(response.data);
            setCategories(response.data);
        })
        .catch(err => {
            console.log(err);
        });
        
        setIsLoading(true);
    }, []);

    const loadData = useCallback(() => {
        // setIsLoading(null);
        if (id !== null || id !== undefined) {
            let uri = `${process.env.REACT_APP_API_URI}service/${id}`;
    
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('Bearer')}`,
                }
            }
            
            axios.get(uri, config)
            .then(response => {
                // console.log(response.data);
                let defaultValues = {
                    name: response.data.name,
                    description: response.data.description,
                    category: response.data.category_id,
                    status: response.data.status
                }

                // setPreloader([]);
                // setData(defaultValues);
                reset(defaultValues);
            })
            .catch(err => {
                console.log(err);
            });
        }
    }, [id, reset]);

    const sendData = (data) => {
        let uri = `${process.env.REACT_APP_API_URI}service/${id}?_method=PUT`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }
        // console.log(data);
        axios.post(uri, data, config)
        .then(response => {
            let result = {
                type: 'success',
                message: response.data.message
            };

            generateAlert(result);
            
            reset();
            clearErrors();
            navigate('/services');
        })
        .catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        if (isLoading === false) {
            loadCategories();
            loadData();
        }
    }, [loadData, isLoading, loadCategories]);

    return (
        <div id="UpdateService">
            <div className="pt-5 flex items-center justify-center">
                <div className=" mx-auto text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-dark-theme sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </div>
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Actualizando servicio</h3>
                </div>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6">
                <Link to="/services" className="bg-white px-4 py-3 rounded-lg text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" >
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Regresar
                </Link>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <form id="update-service" onSubmit={ handleSubmit(sendData) } >
                        <div className="w-full mb-4">
                            <h2 className="text-base font-normal">Los campos con&nbsp;<span className="text-error">*</span>&nbsp;son requeridos.</h2>
                        </div>
                        <div className="flex flex-wrap -m-2">
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="names" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nombre del servicio&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                                        autoComplete="nope"
                                        {...register("name", {
                                            required: "El nombre del servicio es requerido"
                                        })}
                                        
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="name"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="category" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Categoría&nbsp;<span className="text-error">*</span></label>
                                    <select 
                                        id="category" 
                                        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        defaultValue={ "null" }
                                        {...register("category", {
                                            required: "Seleccione la categoría del servicio",
                                            validate: (value) => {
                                                if (value === "null") {
                                                    return "Seleccione la categoría del servicio";
                                                }
                                            },
                                        })}
                                    >
                                        <option hidden disabled selected value="null">[Seleccione una opción]</option>
                                        { categories.map((item, index) => {
                                            return (
                                                <option value={ item.id } key={ index }>{ item.name }</option>
                                            );
                                        }) }    
                                    </select>
                                    <ErrorMessage
                                        errors={ errors }
                                        name="category"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="description" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Descripción del servicio&nbsp;<span className="text-error">*</span></label>
                                    <textarea id="description" className="w-full h-32 resize-none bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" autoComplete="nope"
                                        {...register("description", {
                                            required: "La descripción del servicio es requerida"
                                        })}
                                    ></textarea>
                                    <ErrorMessage
                                        errors={ errors }
                                        name="description"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="u-status" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Estado&nbsp;<span className="text-error">*</span></label>
                                    <select id="u-status" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    {...register("status", {
                                        required: "Seleccione un estado"
                                    })} >
                                        <option value={ 1 }>Activo</option>
                                        <option value={ 2 }>Inactivo</option>
                                    </select>
                                    <ErrorMessage
                                        errors={ errors }
                                        name="status"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full pt-8 flex justify-end">
                            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-theme font-bold text-white transition-colors hover:bg-dark-theme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-0 sm:w-auto sm:text-base">Actualizar</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default UpdateService;
