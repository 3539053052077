import axios from 'axios';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';

import { sessionActive, generateAlert } from '../../utils/utils';

const UpdateSchedule = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    let activeDays = [];

    const [activeMonday, setActiveMonday] = useState(false);
    const [activeTuesday, setActiveTuesday] = useState(false);
    const [activeWednesday, setActiveWednesday] = useState(false);
    const [activeThursday, setActiveThursday] = useState(false);
    const [activeFriday, setActiveFriday] = useState(false);
    const [activeSaturday, setActiveSaturday] = useState(false);
    const [activeSunday, setActiveSunday] = useState(false);

    const checkMonday = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setActiveMonday(true);
            data.active_monday = true;

            activeDays.push({
                day: 'monday',
                active: true
            });
        }

        if (!is_checked) {
            setActiveMonday(false);
            data.active_monday = false;

            activeDays.pop(item => item.day === 'monday');
        }
    };

    const checkTuesday = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setActiveTuesday(true);
            data.active_tuesday = true;

            activeDays.push({
                day: 'tuesday',
                active: true
            });
        }

        if (!is_checked) {
            setActiveTuesday(false);
            data.active_tuesday = false;

            activeDays.pop(item => item.day === 'tuesday');
        }
    };

    const checkWednesday = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setActiveWednesday(true);
            data.active_wednesday = true;

            activeDays.push({
                day: 'wednesday',
                active: true
            });
        }

        if (!is_checked) {
            setActiveWednesday(false);
            data.active_wednesday = false;

            activeDays.pop(item => item.day === 'wednesday');
        }
    };

    const checkThursday = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setActiveThursday(true);
            data.active_thursday = true;

            activeDays.push({
                day: 'thursday',
                active: true
            });
        }

        if (!is_checked) {
            setActiveThursday(false);
            data.active_thursday = false;

            activeDays.pop(item => item.day === 'thursday');
        }
    };

    const checkFriday = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setActiveFriday(true);
            data.active_friday = true;

            activeDays.push({
                day: 'friday',
                active: true
            });
        }
        
        if (!is_checked) {
            setActiveFriday(false);
            data.active_friday = false;

            activeDays.pop(item => item.day === 'friday');
        }
    };

    const checkSaturday = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setActiveSaturday(true);
            data.active_saturday = true;

            activeDays.push({
                day: 'saturday',
                active: true
            });
        }

        if (!is_checked) {
            setActiveSaturday(false);
            data.active_saturday = false;

            activeDays.pop(item => item.day === 'saturday');
        }
    };

    const checkSunday = (e) => {
        let is_checked = e.target.checked;
        
        if (is_checked) {
            setActiveSunday(true);
            data.active_sunday = true;

            activeDays.push({
                day: 'sunday',
                active: true
            });
        }
        
        if (!is_checked) {
            setActiveSunday(false);
            data.active_sunday = false;

            activeDays.pop(item => item.day === 'sunday');
        }
    };

    const sendData = (items) => {
        let uri = `${process.env.REACT_APP_API_URI}attention-schedule/${id}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${Cookies.get('Bearer')}`,
            }
        }

        if (activeDays.length < 1) {
            generateAlert({
                type: 'error',
                message: 'Seleccione al menos un día para el horario'
            });

            return;
        }
        
        axios.put(uri, JSON.stringify(items), config)
        .then(response => {
            let result = {
                type: 'success',
                message: response.data.message
            };
            
            generateAlert(result);
            
            reset();
            clearErrors();
            setActiveMonday(false);
            setActiveTuesday(false);
            setActiveWednesday(false);
            setActiveThursday(false);
            setActiveFriday(false);
            setActiveSaturday(false);
            setActiveSunday(false);
            navigate("/attention-schedules");
            // console.log(response.data);
        })
        .catch(err => {
            console.log(err);
        });
    };

    const loadData = useCallback(() => {
        if (id !== null || id !== undefined) {
            let uri = `${process.env.REACT_APP_API_URI}attention-schedule/${id}`;
    
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('Bearer')}`,
                }
            }
            
            axios.get(uri, config)
            .then(response => {
                let schedules = response.data.schedules;

                let defaultValues = {
                    center: response.data.center,
                    active_monday: false,
                    active_tuesday: false,
                    active_wednesday: false,
                    active_thursday: false,
                    active_friday: false,
                    active_saturday: false,
                    active_sunday: false
                }

                for (let i = 0; i < schedules.length; i++) {           
                    if (schedules[i] !== null) {
                        if (schedules[i].day_id === 1) {
                            setActiveMonday(true);
                            defaultValues.active_monday = true;
                            defaultValues.monday_entry = schedules[i].entry;
                            defaultValues.monday_departure = schedules[i].departure;

                        }
    
                        if (schedules[i].day_id === 2) {
                            setActiveTuesday(true);
                            defaultValues.active_tuesday = true;
                            defaultValues.tuesday_entry = schedules[i].entry;
                            defaultValues.tuesday_departure = schedules[i].departure;
                        }
    
                        if (schedules[i].day_id === 3) {
                            setActiveWednesday(true);
                            defaultValues.active_wednesday = true;
                            defaultValues.wednesday_entry = schedules[i].entry;
                            defaultValues.wednesday_departure = schedules[i].departure;
                        }
    
                        if (schedules[i].day_id === 4) {
                            setActiveThursday(true);
                            defaultValues.active_thursday = true;
                            defaultValues.thursday_entry = schedules[i].entry;
                            defaultValues.thursday_departure = schedules[i].departure;
                        }
    
                        if (schedules[i].day_id === 5) {
                            setActiveFriday(true);
                            defaultValues.active_friday = true;
                            defaultValues.friday_entry = schedules[i].entry;
                            defaultValues.friday_departure = schedules[i].departure;
                        }
    
                        if (schedules[i].day_id === 6) {
                            setActiveSaturday(true);
                            defaultValues.active_saturday = true;
                            defaultValues.saturday_entry = schedules[i].entry;
                            defaultValues.saturday_departure = schedules[i].departure;
                        }
    
                        if (schedules[i].day_id === 7) {
                            setActiveSunday(true);
                            defaultValues.active_sunday = true;
                            defaultValues.sunday_entry = schedules[i].entry;
                            defaultValues.sunday_departure = schedules[i].departure;
                        }
                    }
                }
                // setPreloader([]);
                setData(defaultValues);
                reset(defaultValues);
            })
            .catch(err => {
                console.log(err);
            });
        }

        setIsLoading(true);
    }, [id, reset]);

    useEffect(() => {
        sessionActive(location, navigate);

        if (isLoading === false) {
            loadData();
        }
    }, [isLoading, loadData, navigate, location]);

    return (
        <div id="UpdateSchedule">
            <div className="pt-5 flex items-center justify-center">
                <div className=" mx-auto text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-dark-theme sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </div>
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Actualizando horario de atención</h3>
                </div>
            </div>
            <div className="flex mx-auto flex-wrap mb-10 justify-start mt-6">
                <Link to="/attention-schedules" className="bg-white px-4 py-3 rounded-lg text-base text-theme border-2 border-theme transition-colors hover:bg-theme hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full" >
                    <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Regresar
                </Link>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <div className="text-center mb-5">
                        <h1 className="text-xl">Institución:</h1>
                        <h2 className="text-xl font-bold">{ data.center }</h2>
                    </div>
                    <form id="update-schedule" 
                        onSubmit={ handleSubmit(sendData) } 
                    >
                        
                        <div className="w-full mb-4">
                            <h2 className="text-base font-normal">Los campos con&nbsp;<span className="text-error">*</span>&nbsp;son requeridos.</h2>
                        </div>
                        <div className="flex flex-wrap -m-2">
                            {/* Monday */}
                            <div className="p-2 xl:w-1/6 w-full flex items-center justify-center">
                                <div className="relative mb-2">
                                    {/* <input type="checkbox" className="accent-theme hover:accent-dark-theme" id="active-moday" name="active-modal" /> */}
                                    <label htmlFor="active_monday" className="flex items-center cursor-pointer relative">
                                        <input type="checkbox" id="active_monday" 
                                            
                                            className="sr-only"
                                            {...register("active_monday")}
                                            onChange={ checkMonday }
                                        />
                                        <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                        <span className="ml-3 text-gray-600 text-base font-medium">Lunes</span>
                                    </label>
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="monday_entry" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Entrada&nbsp;<span className="text-error">*</span></label>
                                    { activeMonday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                        </select>
                                    ) }
                                    { activeMonday === true && (
                                        <>
                                            <select 
                                                id="monday_entry" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.monday_entry === undefined ? "null" : data.monday_entry }
                                                {...register("monday_entry", {
                                                    required: "Seleccione una hora de entrada",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de entrada";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="monday_entry"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="monday_departure" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Salida&nbsp;<span className="text-error">*</span></label>
                                    { activeMonday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                        </select>
                                    ) }
                                    { activeMonday === true && (
                                        <>
                                            <select 
                                                id="monday_departure" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.monday_departure === undefined ? "null" : data.monday_departure }
                                                {...register("monday_departure", {
                                                    required: "Seleccione una hora de salida",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de salida";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="monday_departure"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="xl:w-1/6 w-0"></div>

                            {/* Tuesday */}
                            <div className="p-2 xl:w-1/6 w-full flex items-center justify-center">
                                <div className="relative mb-2">
                                    {/* <input type="checkbox" className="accent-theme hover:accent-dark-theme" id="active-moday" name="active-modal" /> */}
                                    <label htmlFor="active_tuesday" className="flex items-center cursor-pointer relative">
                                        <input type="checkbox" id="active_tuesday" 
                                            
                                            className="sr-only"
                                            {...register("active_tuesday")}
                                            onChange={ checkTuesday }
                                        />
                                        <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                        <span className="ml-3 text-gray-600 text-base font-medium">Martes</span>
                                    </label>
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="tuesday_entry" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Entrada&nbsp;<span className="text-error">*</span></label>
                                    { activeTuesday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                        </select>
                                    ) }
                                    { activeTuesday === true && (
                                        <>
                                            <select 
                                                id="tuesday_entry" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.tuesday_entry === undefined ? "null" : data.tuesday_entry }
                                                {...register("tuesday_entry", {
                                                    required: "Seleccione una hora de entrada",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de entrada";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="tuesday_entry"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="tuesday_departure" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Salida&nbsp;<span className="text-error">*</span></label>
                                    { activeTuesday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                        </select>
                                    ) }
                                    { activeTuesday === true && (
                                        <>
                                            <select 
                                                id="tuesday_departure" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.tuesday_departure === undefined ? "null" : data.tuesday_departure }
                                                {...register("tuesday_departure", {
                                                    required: "Seleccione una hora de salida",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de salida";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="tuesday_departure"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="xl:w-1/6 w-0"></div>

                            {/* Wednesday */}
                            <div className="p-2 xl:w-1/6 w-full flex items-center justify-center">
                                <div className="relative mb-2">
                                    {/* <input type="checkbox" className="accent-theme hover:accent-dark-theme" id="active-moday" name="active-modal" /> */}
                                    <label htmlFor="active_wednesday" className="flex items-center cursor-pointer relative">
                                        <input type="checkbox" id="active_wednesday"  
                                            
                                            className="sr-only"
                                            {...register("active_wednesday")}
                                            onChange={ checkWednesday }
                                        />
                                        <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                        <span className="ml-3 text-gray-600 text-base font-medium">Miércoles</span>
                                    </label>
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="wednesday_entry" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Entrada&nbsp;<span className="text-error">*</span></label>
                                    { activeWednesday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                        </select>
                                    ) }
                                    { activeWednesday === true && (
                                        <>
                                            <select 
                                                id="wednesday_entry" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.wednesday_entry === undefined ? "null" : data.wednesday_entry }
                                                {...register("wednesday_entry", {
                                                    required: "Seleccione una hora de entrada",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de entrada";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="wednesday_entry"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="wednesday_departure" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Salida&nbsp;<span className="text-error">*</span></label>
                                    { activeWednesday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                        </select>
                                    ) }
                                    { activeWednesday === true && (
                                        <>
                                            <select 
                                                id="wednesday_departure" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.wednesday_departure === undefined ? "null" : data.wednesday_departure }
                                                {...register("wednesday_departure", {
                                                    required: "Seleccione una hora de salida",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de salida";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="wednesday_departure"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="xl:w-1/6 w-0"></div>

                            {/* Thursday */}
                            <div className="p-2 xl:w-1/6 w-full flex items-center justify-center">
                                <div className="relative mb-2">
                                    {/* <input type="checkbox" className="accent-theme hover:accent-dark-theme" id="active-moday" name="active-modal" /> */}
                                    <label htmlFor="active_thursday" className="flex items-center cursor-pointer relative">
                                        <input type="checkbox" id="active_thursday"   
                                            
                                            className="sr-only"
                                            {...register("active_thursday")}
                                            onChange={ checkThursday }
                                        />
                                        <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                        <span className="ml-3 text-gray-600 text-base font-medium">Jueves</span>
                                    </label>
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="thursday_entry" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Entrada&nbsp;<span className="text-error">*</span></label>
                                    { activeThursday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                        </select>
                                    ) }
                                    { activeThursday === true && (
                                        <>
                                            <select 
                                                id="thursday_entry" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.thursday_entry === undefined ? "null" : data.thursday_entry }
                                                {...register("thursday_entry", {
                                                    required: "Seleccione una hora de entrada",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de entrada";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="thursday_entry"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="thursday_departure" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Salida&nbsp;<span className="text-error">*</span></label>
                                    { activeThursday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                        </select>
                                    ) }
                                    { activeThursday === true && (
                                        <>
                                            <select 
                                                id="thursday_departure" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.thursday_departure === undefined ? "null" : data.thursday_departure }
                                                {...register("thursday_departure", {
                                                    required: "Seleccione una hora de salida",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de salida";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="thursday_departure"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="xl:w-1/6 w-0"></div>

                            {/* Friday */}
                            <div className="p-2 xl:w-1/6 w-full flex items-center justify-center">
                                <div className="relative mb-2">
                                    {/* <input type="checkbox" className="accent-theme hover:accent-dark-theme" id="active-moday" name="active-modal" /> */}
                                    <label htmlFor="active_friday" className="flex items-center cursor-pointer relative">
                                        <input type="checkbox" id="active_friday"   
                                            
                                            className="sr-only"
                                            {...register("active_friday")}
                                            onChange={ checkFriday }
                                        />
                                        <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                        <span className="ml-3 text-gray-600 text-base font-medium">Viernes</span>
                                    </label>
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="friday_entry" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Entrada&nbsp;<span className="text-error">*</span></label>
                                    { activeFriday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                        </select>
                                    ) }
                                    { activeFriday === true && (
                                        <>
                                            <select 
                                                id="friday_entry" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.friday_entry === undefined ? "null" : data.friday_entry }
                                                {...register("friday_entry", {
                                                    required: "Seleccione una hora de entrada",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de entrada";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="friday_entry"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="friday_departure" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Salida&nbsp;<span className="text-error">*</span></label>
                                    { activeFriday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                        </select>
                                    ) }
                                    { activeFriday === true && (
                                        <>
                                            <select 
                                                id="friday_departure" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.friday_departure === undefined ? "null" : data.friday_departure }
                                                {...register("friday_departure", {
                                                    required: "Seleccione una hora de salida",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de salida";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="friday_departure"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="xl:w-1/6 w-0"></div>

                            {/* Saturday */}
                            <div className="p-2 xl:w-1/6 w-full flex items-center justify-center">
                                <div className="relative mb-2">
                                    {/* <input type="checkbox" className="accent-theme hover:accent-dark-theme" id="active-moday" name="active-modal" /> */}
                                    <label htmlFor="active_saturday" className="flex items-center cursor-pointer relative">
                                        <input type="checkbox" id="active_saturday"    
                                            
                                            className="sr-only"
                                            {...register("active_saturday")}
                                            onChange={ checkSaturday }
                                        />
                                        <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                        <span className="ml-3 text-gray-600 text-base font-medium">Sábado</span>
                                    </label>
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="saturday_entry" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Entrada&nbsp;<span className="text-error">*</span></label>
                                    { activeSaturday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                        </select>
                                    ) }
                                    { activeSaturday === true && (
                                        <>
                                            <select 
                                                id="saturday_entry" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.saturday_entry === undefined ? "null" : data.saturday_entry }
                                                {...register("saturday_entry", {
                                                    required: "Seleccione una hora de entrada",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de entrada";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="saturday_entry"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="saturday_departure" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Salida&nbsp;<span className="text-error">*</span></label>
                                    { activeSaturday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                        </select>
                                    ) }
                                    { activeSaturday === true && (
                                        <>
                                            <select 
                                                id="saturday_departure" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.saturday_departure === undefined ? "null" : data.saturday_departure }
                                                {...register("saturday_departure", {
                                                    required: "Seleccione una hora de salida",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de salida";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="saturday_departure"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="xl:w-1/6 w-0"></div>

                            {/* Sunday */}
                            <div className="p-2 xl:w-1/6 w-full flex items-center justify-center">
                                <div className="relative mb-2">
                                    {/* <input type="checkbox" className="accent-theme hover:accent-dark-theme" id="active-moday" name="active-modal" /> */}
                                    <label htmlFor="active_sunday" className="flex items-center cursor-pointer relative">
                                        <input type="checkbox" id="active_sunday"     
                                            
                                            className="sr-only"
                                            {...register("active_sunday")}
                                            onChange={ checkSunday }
                                        />
                                        <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                        <span className="ml-3 text-gray-600 text-base font-medium">Domingo</span>
                                    </label>
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="sunday_entry" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Entrada&nbsp;<span className="text-error">*</span></label>
                                    { activeSunday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                        </select>
                                    ) }
                                    { activeSunday === true && (
                                        <>
                                            <select 
                                                id="sunday_entry" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.sunday_entry === undefined ? "null" : data.sunday_entry }
                                                {...register("sunday_entry", {
                                                    required: "Seleccione  una hora de entrada",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de entrada";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de entrada]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="sunday_entry"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="p-2 xl:w-2/6 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="sunday_departure" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Salida&nbsp;<span className="text-error">*</span></label>
                                    { activeSunday === false && (
                                        <select className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        disabled>
                                            <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                        </select>
                                    ) }
                                    { activeSunday === true && (
                                        <>
                                            <select 
                                                id="sunday_departure" 
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                defaultValue={ data.sunday_departure === undefined ? "null" : data.sunday_departure }
                                                {...register("sunday_departure", {
                                                    required: "Seleccione una hora de salida",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Seleccione una hora de salida";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled selected value="null">[Seleccione una hora de salida]</option>
                                                <option value="05:00 am">05:00 am</option>
                                                <option value="05:30 am">05:30 am</option>
                                                <option value="06:00 am">06:00 am</option>
                                                <option value="06:30 am">06:30 am</option>
                                                <option value="07:00 am">07:00 am</option>
                                                <option value="07:30 am">07:30 am</option>
                                                <option value="08:00 am">08:00 am</option>
                                                <option value="08:30 am">08:30 am</option>
                                                <option value="09:00 am">09:00 am</option>
                                                <option value="09:30 am">09:30 am</option>
                                                <option value="10:00 am">10:00 am</option>
                                                <option value="10:30 am">10:30 am</option>
                                                <option value="11:00 am">11:00 am</option>
                                                <option value="11:30 am">11:30 am</option>
                                                <option value="12:00 am">12:00 am</option>
                                                <option value="12:30 am">12:30 am</option>
                                                <option value="01:00 pm">01:00 pm</option>
                                                <option value="01:30 pm">01:30 pm</option>
                                                <option value="02:00 pm">02:00 pm</option>
                                                <option value="02:30 pm">02:30 pm</option>
                                                <option value="03:00 pm">03:00 pm</option>
                                                <option value="03:30 pm">03:30 pm</option>
                                                <option value="04:00 pm">04:00 pm</option>
                                                <option value="04:30 pm">04:30 pm</option>
                                                <option value="05:00 pm">05:00 pm</option>
                                                <option value="05:30 pm">05:30 pm</option>
                                                <option value="06:00 pm">06:00 pm</option>
                                                <option value="06:30 pm">06:30 pm</option>
                                                <option value="07:00 pm">07:00 pm</option>
                                                <option value="07:30 pm">07:30 pm</option>
                                                <option value="08:00 pm">08:00 pm</option>
                                                <option value="08:30 pm">08:30 am</option>
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="sunday_departure"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </>
                                    ) }
                                </div>
                            </div>
                            <div className="xl:w-1/6 w-0"></div>
                        </div>
                        <div className="w-full pt-8 flex justify-end">
                            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-theme font-bold text-white transition-colors hover:bg-dark-theme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-0 sm:w-auto sm:text-base">Actualizar</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default UpdateSchedule;
