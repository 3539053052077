import Cookies from 'js-cookie';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {
    let auth = {
        'manager': (Cookies.get('Manager') !== undefined && Cookies.get('Manager') === '1') ? true : false,
    }

    return (
        auth.manager ? <Outlet /> : <Navigate to="/dashboard" />
    );
};

export default PrivateRoutes;
